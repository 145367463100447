import { GetVariableValue } from "../hooks";
import {
  BrandingColorStyles,
  BrandingThemeType,
  Resource,
  Screen,
  ScreenSize,
  setColors,
} from "./index";

export const createScreen = async (
  id: string,
  screenConfig: Screen,
  rootElement: HTMLElement,
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType,
  resources: Resource[],
  getVariableValue: GetVariableValue,
  size: ScreenSize,
  hasTabBar: boolean,
  leading?: boolean,
  trailing?: boolean
) => {
  const { backgroundColor, backgroundGradient, backgroundPattern } =
    screenConfig;

  const screen = document.createElement("div");
  rootElement.appendChild(screen);

  screen.id = id;
  screen.style.width = "100%";
  screen.style.height = "100%";
  screen.style.display = "flex";
  screen.style.flexDirection = "column";
  screen.className = leading
    ? "leading"
    : trailing
    ? "trailing"
    : size === ScreenSize.responsive && hasTabBar
    ? `${size}WithTabBar`
    : size;

  await setColors(
    id,
    screen,
    colorStyles,
    theme,
    resources,
    getVariableValue,
    backgroundColor,
    backgroundGradient,
    backgroundPattern
  );

  return screen;
};

export const createScreenCorners = (screen: HTMLElement) =>
  [
    "corner-topLeft",
    "corner-topRight",
    "corner-bottomRight",
    "corner-bottomLeft",
  ].forEach((id) => {
    const corner = document.createElement("div");
    corner.id = id;
    screen.appendChild(corner);
  });
