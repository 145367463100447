export enum ValueType {
  any = "any",
  string = "string",
  number = "number",
  image = "image",
  video = "video",
  audio = "audio",
  file = "file",
  color = "color",
  date = "date",
  dateTime = "dateTime",
  insets = "insets",
  boolean = "boolean",
  url = "url",
  list = "list",
  user = "user",
  record = "record",
  array = "array",
  coordinate = "coordinate",
  calendarStyle = "calendarStyle",
  calendarItemSelection = "calendarItemSelection",
  accessLevel = "accessLevel",
  richText = "richText",
  json = "json",
  keyValueMap = "keyValueMap",
  vector = "vector",
}

export interface MediaConstant {
  resourceId?: string;
}

export enum CalendarStyleConstant {
  monthlyHorizontal = "monthlyHorizontal",
  monthlyVertical = "monthlyVertical",
  weekly = "weekly",
}

export enum CalendarItemSelectionConstant {
  noSelection = "noSelection",
  rangeStart = "rangeStart",
  rangeEnd = "rangeEnd",
  rangeMiddle = "rangeMiddle",
  single = "single",
}

export interface InsetsConstant {
  leading?: string;
  trailing?: string;
  top?: string;
  bottom?: string;
}

export interface CoordinateConstant {
  latitude?: string;
  longitude?: string;
}

export enum VariableSourceType {
  localVariable = "localVariable",
  globalVariable = "globalVariable",
  contextVariable = "contextVariable",
  collection = "collection",
  component = "component",
  flow = "flow",
}

export enum TableQueryFilterOperator {
  equal = "equal",
  notEqual = "notEqual",
  less = "less",
  lessOrEqual = "lessOrEqual",
  greater = "greater",
  greaterOrEqual = "greaterOrEqual",
  contains = "contains",
}

export interface TableQueryFilter {
  field: string;
  operator: TableQueryFilterOperator;
  value: AnyVariable;
}

export enum TableQueryOrder {
  ascending = "ascending",
  descending = "descending",
}

export interface TableQuery {
  filters?: TableQueryFilter[];
  orderedBy?: string;
  order?: TableQueryOrder;
}

export interface ItemSize {
  width?: string;
  height?: string;
}

export enum VariableTransformTransform {
  boolNot = "boolNot",
  field = "field",
  cast = "cast",
  resize = "resize",
  formatTimeInterval = "formatTimeInterval",
  formatTimeAgo = "formatTimeAgo",
  first = "first",
  exists = "exists",
  trimWhitespacesAndNewlines = "trimWhitespacesAndNewlines",
  isEmpty = "isEmpty",
  isNotEmpty = "isNotEmpty",
  compareEqual = "compareEqual",
  compareNotEqual = "compareNotEqual",
  compareGreater = "compareGreater",
  compareLess = "compareLess",
  compareGreaterOrEqual = "compareGreaterOrEqual",
  compareLessOrEqual = "compareLessOrEqual",
  conditionalValue = "conditionalValue",
  conversationImage = "conversationImage",
  conversationTitle = "conversationTitle",
  year = "year",
  monthYearLong = "monthYearLong",
  monthYearDateLong = "monthYearDateLong",
  identifier = "identifier",
  productIdentifier = "productIdentifier",
  anyTrue = "anyTrue",
  allTrue = "allTrue",
}

export enum VariableTransformResizeType {
  proportionally = "proportionally",
  exact = "exact",
}

export interface VariableTransform {
  transform: VariableTransformTransform;
  fieldName?: string;
  query?: TableQuery;
  value?: AnyVariable;
  value2?: AnyVariable;
  size?: ItemSize;
  resizeType?: VariableTransformResizeType;
  participantsKey?: string;
  participantImageKey?: string;
  participantNameKeys?: string[];
}

export interface VariableSource {
  type: VariableSourceType;
  variableName?: string;
  componentName?: string;
  collection?: CollectionVariable;
  selector?: StringVariable;
  fieldName?: string;
  query?: TableQuery;
  name?: string;
  parameters?: InputParameter[];
  transforms?: VariableTransform[];
}

export interface BooleanVariable {
  constant?: boolean;
  source?: VariableSource;
}

export interface StringVariable {
  constant?: string;
  source?: VariableSource;
}

export interface MediaVariable {
  constant?: MediaConstant;
  source?: VariableSource;
}

export interface InsetsVariable {
  constant?: InsetsConstant;
  source?: VariableSource;
}

export interface CoordinateVariable {
  constant?: CoordinateConstant;
  source?: VariableSource;
}

export interface CalendarStyleVariable {
  constant?: CalendarStyleConstant;
  source?: VariableSource;
}

export interface CollectionVariable {
  name: string;
  params?: {
    [key: string]: StringVariable;
  };
  // for local
  collectionId?: string;
}

export enum AnyVariablTypeKey {
  urlConstant = "urlConstant",
  accessLevelConstant = "accessLevelConstant",
  booleanConstant = "booleanConstant",
  dateConstant = "dateConstant",
  dateTimeConstant = "dateTimeConstant",
  textConstant = "textConstant",
  videoConstant = "videoConstant",
  numberConstant = "numberConstant",
  imageConstant = "imageConstant",
  colorConstant = "colorConstant",
  insetsConstant = "insetsConstant",
  coordinateConstant = "coordinateConstant",
  calendarStyleConstant = "calendarStyleConstant",
  arrayConstant = "arrayConstant",
  source = "source",
}

export interface AnyVariable {
  [AnyVariablTypeKey.urlConstant]?: string;
  [AnyVariablTypeKey.accessLevelConstant]?: string;
  [AnyVariablTypeKey.booleanConstant]?: boolean;
  [AnyVariablTypeKey.dateConstant]?: string;
  [AnyVariablTypeKey.dateTimeConstant]?: string;
  [AnyVariablTypeKey.textConstant]?: LocalizedContent;
  [AnyVariablTypeKey.videoConstant]?: MediaConstant;
  [AnyVariablTypeKey.numberConstant]?: string;
  [AnyVariablTypeKey.imageConstant]?: MediaConstant;
  [AnyVariablTypeKey.colorConstant]?: string;
  [AnyVariablTypeKey.insetsConstant]?: InsetsConstant;
  [AnyVariablTypeKey.coordinateConstant]?: CoordinateConstant;
  [AnyVariablTypeKey.calendarStyleConstant]?: CalendarStyleConstant;
  [AnyVariablTypeKey.arrayConstant]?: AnyVariable[];
  [AnyVariablTypeKey.source]?: VariableSource;
}

export enum ImageLayout {
  fill = "fill",
  fit = "fit",
  stretch = "stretch",
  center = "center",
}

export interface Action {
  options?: ActionOption;
  enabled?: BooleanVariable;
  actionType?: ActionType;
  parameters?: InputParameter[];
  collection?: CollectionVariable;
  record?: VariableSource;
  recordModifications?: RecordModification[];
  componentName?: string;
  variable?: AnyVariable;
  valueTarget?: VariableSource;
  transforms?: VariableTransform[];
  variableContextKey?: string;
  description?: LocalizedContent;
  subActions?: Action[];
  fallbackActions?: Action[];
}

export enum ActionType {
  placeholder = "placeholder",
  acceptTerms = "acceptTerms",
  check = "check",
  checkAccessLevels = "checkAccessLevels",
  checkNotificationsEnabled = "checkNotificationsEnabled",
  checkLocationTrackingEnabled = "checkLocationTrackingEnabled",
  checkIdfaAndCookieTrackingEnabled = "checkIdfaAndCookieTrackingEnabled",
  appleAuthentication = "appleAuthentication",
  googleAuthentication = "googleAuthentication",
  anonymousAuthentication = "anonymousAuthentication",
  createUserWithEmailAndPassword = "createUserWithEmailAndPassword",
  signInWithEmailAndPassword = "signInWithEmailAndPassword",
  sendPasswordResetEmail = "sendPasswordResetEmail",
  sendSignInLinkToEmail = "sendSignInLinkToEmail",
  reSendSignInLinkToEmail = "reSendSignInLinkToEmail",
  enableNotifications = "enableNotifications",
  enableIdfaAndCookieTracking = "enableIdfaAndCookieTracking",
  enableLocationTracking = "enableLocationTracking",
  logout = "logout",
  openEmail = "openEmail",
  openWeb = "openWeb",
  openLink = "openLink",
  checkoutSelected = "checkoutSelected",
  restorePurchases = "restorePurchases",
  deleteAccount = "deleteAccount",
  updateRecord = "updateRecord",
  createRecord = "createRecord",
  deleteRecord = "deleteRecord",
  setValue = "setValue",
  clearValue = "clearValue",
  getPhoto = "getPhoto",
  getVideo = "getVideo",
  getFile = "getFile",
  navigate = "navigate",
  navigateBack = "navigateBack",
  show = "show",
  openDialog = "openDialog",
  scrollToStart = "scrollToStart",
  scrollToEnd = "scrollToEnd",
  subscribe = "subscribe",
  openDocument = "openDocument",
  runFlow = "runFlow",
  handleDeeplink = "handleDeeplink",
}

export interface RecordModification {
  type: "set";
  fieldName?: string;
  value?: AnyVariable;
}

export interface InputParameter {
  name?: string;
  value?: AnyVariable;
}

export interface ActionOption {
  email?: string;
  screen?: string;
  name?: string;
  link?: StringVariable;
}

export interface LocalizedContent {
  key: string;
  locales: { [key: string]: string };
  values?: VariableSource[];
}

export enum TextAlignment {
  leading = "leading",
  trailing = "trailing",
  center = "center",
}

export interface TabBar {
  id: string;
  name: string;
  backgroundColor?: StringVariable;
  tabs?: TabBarItem[];
}

export interface TabBarItem {
  screen: string;
  title: LocalizedContent;
  image: MediaVariable;
  visible: BooleanVariable;
}

export interface ScreenParameter {
  parameter: string;
  required?: boolean;
  inout?: boolean;
  type: ValueType;
  accept?: ValueType;
  dataObject?: string;
  collection?: CollectionVariable;
}

export enum ComponentType {
  view = "view",
  spacer = "spacer",
  divider = "divider",
  stack = "stack",
  overlay = "overlay",
  carousel = "carousel",
  datePicker = "datePicker",
  button = "button",
  image = "image",
  text = "text",
  textInput = "textInput",
  video = "video",
  list = "list",
  scroll = "scroll",
  map = "map",
  product = "product",
  themePicker = "themePicker",
  notificationsSettings = "notificationsSettings",
  calendar = "calendar",
  videoPlayer = "videoPlayer",
  slider = "slider",
  toggle = "toggle",
  progressIndicator = "progressIndicator",
}

export enum ComponentAlignment {
  fill = "fill",
  leading = "leading",
  start = "start",
  top = "top",
  topLeading = "topLeading",
  topCenter = "topCenter",
  topTrailing = "topTrailing",
  centerLeading = "centerLeading",
  center = "center",
  centerTrailing = "centerTrailing",
  trailing = "trailing",
  end = "end",
  bottom = "bottom",
  bottomLeading = "bottomLeading",
  bottomCenter = "bottomCenter",
  bottomTrailing = "bottomTrailing",
}

export enum ComponentsDirection {
  horizontal = "horizontal",
  vertical = "vertical",
  all = "all",
}

export enum TextInputType {
  general = "general",
  email = "email",
  password = "password",
  number = "number",
  phone = "phone",
}

export enum ImageLocation {
  leading = "leading",
  trailing = "trailing",
  leadingAttached = "leadingAttached",
  trailingAttached = "trailingAttached",
  top = "top",
  bottom = "bottom",
}

export enum ComponentStyle {
  column = "column",
  row = "row",
  grid = "grid",
  gallery = "gallery",
}

export enum TextType {
  plain = "plain",
  markdown = "markdown",
}

export enum ListStyle {
  regular = "regular",
  gallery = "gallery",
}

export enum MapStyle {
  standard = "standard",
  hybrid = "hybrid",
  satellite = "satellite",
}

export interface ScreenComponent {
  id: string;
  componentType: ComponentType;
  name: string;
  componentClass?: string;
  componentStyle?: ComponentStyle;
  visible?: BooleanVariable;
  enabled?: BooleanVariable;
  alpha?: StringVariable;
  cornersRadius?: StringVariable;
  borderColor?: StringVariable;
  borderWidth?: StringVariable;
  width?: StringVariable;
  height?: StringVariable;
  widthHeightRatio?: StringVariable;
  margins?: InsetsConstant;
  backgroundColor?: StringVariable;
  backgroundPattern?: MediaConstant;
  backgroundGradient?: BackgroundGradient;
  actions?: Action[];
  localVariables?: LocalVariable[];
  componentAlignment: ComponentAlignment;
  subComponents?: ScreenComponent[];
  componentsSpacing?: StringVariable;
  removeHiddenComponents?: BooleanVariable;
  componentsDirection?: ComponentsDirection;
  text?: LocalizedContent;
  textAlignment?: TextAlignment;
  textColor?: StringVariable;
  font?: string;
  numberOfLines?: StringVariable;
  textType?: TextType;
  showMoreTextButton?: BooleanVariable;
  textInputType?: TextInputType;
  textInputMultiline?: BooleanVariable;
  placeholderText?: LocalizedContent;
  placeholderTextColor?: StringVariable;
  placeholderTextFont?: string;
  contentPadding?: InsetsVariable;
  image?: MediaVariable;
  imageColor?: StringVariable;
  imageLayout?: ImageLayout;
  imageLocation?: ImageLocation;
  placeholderImage?: MediaVariable;
  placeholderImageColor?: StringVariable;
  placeholderImageLayout?: ImageLayout;
  date?: StringVariable;
  minimumDate?: StringVariable;
  maximumDate?: StringVariable;
  dateFormat?: DateTimeFormat;
  displayDate?: StringVariable;
  video?: MediaVariable;
  autoplay?: BooleanVariable;
  loop?: BooleanVariable;
  duration?: number;
  showScrollBar?: BooleanVariable;
  reversed?: BooleanVariable;
  listItemContextKey?: string;
  listItems?: StringVariable;
  listStyle?: ListStyle;
  listItemCoordinate?: CoordinateVariable;
  listItemSectionKey?: AnyVariable;
  mapStyle?: MapStyle;
  showUserLocation?: BooleanVariable;
  coordinate?: CoordinateVariable;
  zoom?: StringVariable;
  product?: StringVariable;
  selected?: BooleanVariable;
  calendarStyle?: CalendarStyleVariable;
  showHeader?: BooleanVariable;
  headerFont?: string;
  headerColor?: StringVariable;
  showWeekdays?: BooleanVariable;
  listItemDate?: StringVariable;
  value?: StringVariable;
  minimumValue?: StringVariable;
  maximumValue?: StringVariable;
  primaryColor?: StringVariable;
  secondaryColor?: StringVariable;
  discrete?: BooleanVariable;
  // for local
  listId?: string;
  indexInList?: number;
}

export enum ScreenSize {
  responsive = "responsive",
  compact = "compact",
}

export enum Module {
  launchScreen = "launchScreen",
  onboarding = "onboarding",
  beforeJoin = "beforeJoin",
  authentication = "authentication",
  emailAuthentication = "emailAuthentication",
  permissions = "permissions",
  home = "home",
  profile = "profile",
  about = "about",
  settings = "settings",
  video = "video",
  paywall = "paywall",
  others = "others",
}

export enum ScreenStatusBarStyle {
  default = "default",
  inverted = "inverted",
  light = "light",
  dark = "dark",
  hidden = "hidden",
}

export enum ScreenTopBarComponentsKey {
  leadingComponents = "leadingComponents",
  headlineComponents = "headlineComponents",
  trailingComponents = "trailingComponents",
}

export type ScreenTopBar = {
  backgroundColor?: StringVariable;
  [ScreenTopBarComponentsKey.leadingComponents]?: ScreenComponent[];
  [ScreenTopBarComponentsKey.headlineComponents]?: ScreenComponent[];
  [ScreenTopBarComponentsKey.trailingComponents]?: ScreenComponent[];
};

export interface ExperimentalPane {
  screenName?: string;
  screenComponentIds?: string[];
  components?: ScreenComponent[];
}

export interface Experimental {
  trailingPane?: ExperimentalPane;
  leadingPane?: ExperimentalPane;
}

export interface Screen {
  path: string;
  id: string;
  screenName: string;
  size: ScreenSize;
  module: Module;
  components?: ScreenComponent[];
  enabled?: BooleanVariable;
  onScreenVisitActions?: Action[];
  statusBarStyle?: ScreenStatusBarStyle;
  backgroundColor?: StringVariable;
  backgroundPattern?: MediaConstant;
  backgroundGradient?: BackgroundGradient;
  topBar?: ScreenTopBar;
  showTopBar?: BooleanVariable;
  showBackButton?: BooleanVariable;
  screenTitle?: LocalizedContent;
  tabBar?: string;
  inputParameters?: ScreenParameter[];
  localVariables?: LocalVariable[];
  experimental?: Experimental;
}

export enum DateTimeFormatFormat {
  short = "short",
  medium = "medium",
  full = "full",
}

export interface DateTimeFormat {
  format?: DateTimeFormatFormat;
  customFormat?: string;
}

export interface IosConfig {
  bundleId: string;
  teamId?: string;
  appGroups?: string[];
  appleKeyId?: string;
  projectName: string;
}

export interface AndroidConfig {
  packageName: string;
  projectName: string;
}

export interface Resource {
  id: string;
  assetId?: string;
  version?: string;
  format: string;
  folder?: string;
  bytes: number;
  width: number;
  height: number;
  signature?: string;
  url: string;
  resourceType: string;
  createdAt: string;
  name: string;
}

export enum BackgroundGradientDirection {
  vertical = "vertical",
  horizontal = "horizontal",
  radial = "radial",
}

export interface BackgroundGradient {
  start: string;
  end: string;
  direction: BackgroundGradientDirection;
}

export enum BrandingColorType {
  background = "background",
  black = "black",
  error = "error",
  errorContainer = "errorContainer",
  inverseOnSurface = "inverseOnSurface",
  inversePrimary = "inversePrimary",
  inverseSurface = "inverseSurface",
  neutral = "neutral",
  onBackground = "onBackground",
  onError = "onError",
  onErrorContainer = "onErrorContainer",
  onPrimary = "onPrimary",
  onPrimaryContainer = "onPrimaryContainer",
  onSecondary = "onSecondary",
  onSecondaryContainer = "onSecondaryContainer",
  onSurface = "onSurface",
  onSurfaceVariant = "onSurfaceVariant",
  onTertiary = "onTertiary",
  onTertiaryContainer = "onTertiaryContainer",
  outline = "outline",
  primary = "primary",
  primaryContainer = "primaryContainer",
  primaryVariant = "primaryVariant",
  secondary = "secondary",
  secondaryContainer = "secondaryContainer",
  secondaryVariant = "secondaryVariant",
  semiTransparentBackground = "semiTransparentBackground",
  shadow = "shadow",
  surface = "surface",
  surfaceVariant = "surfaceVariant",
  tertiary = "tertiary",
  tertiaryContainer = "tertiaryContainer",
  transparent = "transparent",
  white = "white",
}

export enum BrandingThemeType {
  light = "light",
  dark = "dark",
}

export type BrandingColorStyles = {
  [key in BrandingColorType]: { [key in BrandingThemeType]: string };
};

export enum Platform {
  iOS = "iOS",
  Android = "Android",
}

export interface BrandingFontStyleItem {
  fontFamily: string;
  fontStyle: string;
  fontWeight: number;
  fontSize: number;
  lineHeight: number;
  letterSpacing: number;
}

export interface BrandingFontStyle {
  styleName: string;
  categoryName: string;
  ios: BrandingFontStyleItem;
  android: BrandingFontStyleItem;
}

export enum InnerLaunchScreenIcon {
  splashIcon = "splashIcon",
  brandLogo = "brandLogo",
}

export enum LaunchScreenIcon {
  androidIcon = "androidIcon",
  iosIcon = "iosIcon",
}

export interface CollectionProperty {
  name: string;
  type: ValueType;
  position: number;
  isRequired?: boolean;
  isSystem?: boolean;
  isHidden?: boolean;
  accept?: ValueType;
  dataObject?: string;
  collection?: CollectionVariable;
  helpText?: string;
  acceptValues?: string[];
}

export enum CollectionRule {
  Anyone = "Anyone",
  Owner = "Owner",
  "No-one" = "No-one",
}

export interface CollectionPermissions {
  list: string;
  get: string;
  create: string;
  update: string;
  delete: string;
}

export interface CollectionDatasource {
  type: string;
  databaseName?: string;
  collectionName: string;
}

export interface Collection {
  name: string;
  properties?: CollectionProperty[];
  datasource: CollectionDatasource;
  isSystem?: boolean;
  helpText?: string;
  titleField?: string;
  previewScreen?: string;
  indexer?: string;
  permissions?: CollectionPermissions;
}

export interface Storage {
  name: string;
  datasource: {
    type: string;
    bucketName: string;
  };
  indexer: string;
}

export interface DataObject {
  name: string;
  properties?: CollectionProperty[];
}

export interface LocalVariable {
  name: string;
  variable?: AnyVariable;
  type?: ValueType;
  accept?: ValueType;
  dataObject?: string;
  collection?: CollectionVariable;
}

export interface GlobalVariable {
  id: string;
  variableName: string;
  variable: AnyVariable;
}

export interface Flow {
  name: string;
  input: DataObject;
  output: DataObject;
  sourceCode: string;
}

export interface Prompt {
  name: string;
  input: DataObject;
  output: DataObject;
  sourceCode: string;
}

export interface Indexer {
  name: string;
  input: DataObject;
  output: DataObject;
  sourceCode: string;
}

export interface Retriever {
  name: string;
  input: DataObject;
  output: DataObject;
  sourceCode: string;
}

export interface Embedder {
  name: string;
  input: DataObject;
  output: DataObject;
  sourceCode: string;
}

export interface ConfigLogic {
  flows?: Flow[];
  prompts?: Prompt[];
  indexers?: Indexer[];
  retrievers?: Retriever[];
  embedders?: Embedder[];
}

export interface PurchasesBindings {
  collection: string;
  appStoreId?: string;
  stripeId?: string;
  googlePlayId?: string;
}

export interface AssetsBindings {
  collection: string;
  uploadPath: string;
  customBucket?: string;
}

export interface Bindings {
  purchases?: PurchasesBindings;
  assets?: AssetsBindings;
}

export interface Data {
  collections?: Collection[];
  storages?: Storage[];
  dataObjects?: DataObject[];
  globalVariables?: GlobalVariable[];
  bindings?: Bindings;
}

export interface Environment {
  apiUrl?: string;
  domains?: string[];
}

export interface Config {
  environment: Environment;
  ios: IosConfig;
  android: AndroidConfig;
  screens?: Screen[];
  data: Data;
  logic: ConfigLogic;
  codeplatform: {
    syntaxVersion: string;
    template: string;
    templateName: string;
  };
  preview: string;
  resources?: Resource[];
  localization: {
    languages?: string[];
    primaryLanguage: string;
  };
  branding: {
    appName: LocalizedContent;
    icons: { [key in LaunchScreenIcon]: string };
    typography: {
      iosDefaultFontFamily: string;
      androidDefaultFontFamily: string;
      customFontFamilies?: string[];
      fontStyles?: BrandingFontStyle[];
    };
    colorStyles: BrandingColorStyles;
  };
  tabBars?: TabBar[];
}
