import { SetURLSearchParams } from "react-router-dom";
import slugify from "react-slugify";

import { F } from "../context";
import {
  generateRefreshKey,
  getParams,
  GetVariableValue,
  Update,
} from "../hooks";
import {
  checkIdfaAndCookieTrackingEnabled,
  checkNotificationsEnabled,
  enableIdfaAndCookieTracking,
  enableNotifications,
} from "../services/permissions";
import { checkoutSelected } from "../services/stripe";
import {
  Action,
  ActionType,
  findComponent,
  getInputParameters,
  getSearch,
  readableError,
  Screen,
  VariableSourceType,
} from "./index";

export const runActions = async (
  id: string,
  actions: Action[],
  f: F,
  setSearchParams: SetURLSearchParams,
  navigate: (pathname: string, search?: string) => void,
  navigateBack: () => Promise<void>,
  setPaywallOpen: React.Dispatch<React.SetStateAction<boolean>>,
  screens: Screen[],
  screenConfig: Screen,
  getVariableValue: GetVariableValue,
  update: Update,
  indexInList?: number
) => {
  const {
    auth,
    signInWithGoogle,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    sendSignInLinkToEmail,
    reSendSignInLinkToEmail,
    waitProfile,
    runFlow,
  } = f;
  try {
    for (let i = 0; i < actions.length; i++) {
      const action = actions[i];
      const {
        actionType,
        options,
        componentName,
        parameters,
        variable,
        variableContextKey,
        subActions,
        fallbackActions,
      } = action;
      const email = options?.email;
      const screen = options?.screen;
      const name = options?.name;
      const link = options?.link;
      if (
        (actionType === ActionType.check &&
          variable?.source &&
          (await getVariableValue(variable.source)) === "false") ||
        (actionType === ActionType.checkNotificationsEnabled &&
          !checkNotificationsEnabled()) ||
        (actionType === ActionType.checkIdfaAndCookieTrackingEnabled &&
          !checkIdfaAndCookieTrackingEnabled())
      ) {
        if (fallbackActions) {
          await runActions(
            id,
            fallbackActions,
            f,
            setSearchParams,
            navigate,
            navigateBack,
            setPaywallOpen,
            screens,
            screenConfig,
            getVariableValue,
            update,
            indexInList
          );
        }
        break;
      } else if (actionType === ActionType.handleDeeplink) {
        const { pathname, search } = window.location;
        if (
          pathname !== "/" &&
          pathname !== `/${slugify(screenConfig.screenName)}`
        ) {
          navigate(pathname, search);
        } else {
          if (fallbackActions) {
            await runActions(
              id,
              fallbackActions,
              f,
              setSearchParams,
              navigate,
              navigateBack,
              setPaywallOpen,
              screens,
              screenConfig,
              getVariableValue,
              update,
              indexInList
            );
          }
        }
        break;
      } else if (actionType === ActionType.subscribe) {
        const refresh = async () => {
          if (variable?.source && variableContextKey) {
            const value = await getVariableValue(
              variable.source,
              {
                [generateRefreshKey(
                  id,
                  variableContextKey + "variable",
                  indexInList
                )]: refresh,
              },
              undefined,
              true
            );
            await getVariableValue(
              {
                type: VariableSourceType.localVariable,
                variableName: variableContextKey,
              },
              undefined,
              value,
              true
            );
          }
          if (subActions) {
            for (const el of subActions) {
              if (el.actionType === ActionType.updateRecord) {
                await update(
                  screenConfig,
                  getVariableValue,
                  setSearchParams,
                  el
                );
              }
            }
          }
        };
        await refresh();
      } else if (actionType === ActionType.checkAccessLevels) {
        setPaywallOpen(true);
      } else if (actionType === ActionType.show && screen) {
        navigate(`/${slugify(screen)}`);
        break;
      } else if (actionType === ActionType.navigate && screen) {
        if (parameters) {
          const { inputParameter, searchInputParameters } = getInputParameters(
            screens.find((el) => el.screenName === screen)
          );
          if (inputParameter) {
            const parameter = parameters.find(
              (el) => inputParameter.parameter === el.name
            );
            if (parameter?.value?.source) {
              const value = await getVariableValue(parameter.value.source);
              navigate(`-/${slugify(screen)}/${encodeURIComponent(value)}`);
            }
          } else if (searchInputParameters) {
            const searchParameters = parameters.filter(
              (a) => !!searchInputParameters.find((b) => a.name === b.parameter)
            );
            const params = await getParams(searchParameters, getVariableValue);
            navigate(`-/${slugify(screen)}`, getSearch(params));
          }
        } else {
          navigate(`-/${slugify(screen)}`);
        }
        break;
      } else if (actionType === ActionType.navigateBack) {
        await navigateBack();
        break;
      } else if (
        actionType === ActionType.getPhoto ||
        actionType === ActionType.getVideo ||
        actionType === ActionType.getFile ||
        actionType === ActionType.setValue ||
        actionType === ActionType.clearValue ||
        actionType === ActionType.createRecord ||
        actionType === ActionType.updateRecord ||
        actionType === ActionType.deleteRecord
      ) {
        await update(screenConfig, getVariableValue, setSearchParams, action);
      } else if (actionType === ActionType.logout) {
        await auth.signOut();
      } else if (actionType === ActionType.anonymousAuthentication) {
        await auth.signInAnonymously();
      } else if (actionType === ActionType.googleAuthentication) {
        await signInWithGoogle();
        await waitProfile(getVariableValue);
      } else if (
        actionType === ActionType.createUserWithEmailAndPassword &&
        parameters
      ) {
        await createUserWithEmailAndPassword(parameters, getVariableValue);
        await waitProfile(getVariableValue);
      } else if (
        actionType === ActionType.signInWithEmailAndPassword &&
        parameters
      ) {
        await signInWithEmailAndPassword(parameters, getVariableValue);
        await waitProfile(getVariableValue);
      } else if (actionType === ActionType.sendPasswordResetEmail && variable) {
        await sendPasswordResetEmail(variable, getVariableValue);
      } else if (actionType === ActionType.sendSignInLinkToEmail && variable) {
        await sendSignInLinkToEmail(variable, getVariableValue);
      } else if (
        actionType === ActionType.reSendSignInLinkToEmail &&
        variable
      ) {
        await reSendSignInLinkToEmail(variable, getVariableValue);
      } else if (actionType === ActionType.runFlow && name && parameters) {
        await runFlow(name, parameters, getVariableValue);
      } else if (actionType === ActionType.enableNotifications) {
        await enableNotifications();
      } else if (actionType === ActionType.enableIdfaAndCookieTracking) {
        enableIdfaAndCookieTracking();
      } else if (
        (actionType === ActionType.openLink ||
          actionType === ActionType.openWeb) &&
        link
      ) {
        window.open(
          link.source ? await getVariableValue(link.source) : link.constant
        );
      } else if (actionType === ActionType.openEmail && email) {
        window.location.href = `mailto:${options.email}`;
      } else if (actionType === ActionType.checkoutSelected) {
        await checkoutSelected(auth, getVariableValue);
      } else if (
        (actionType === ActionType.scrollToStart ||
          actionType === ActionType.scrollToEnd) &&
        componentName
      ) {
        const component = findComponent(screenConfig, "name", componentName);
        if (component) {
          const { reversed } = component;
          const reversedValue = reversed?.source
            ? (await getVariableValue(reversed.source)) === "true"
            : !!reversed?.constant;
          const elementToScroll = document.querySelector(
            `[id*=".${componentName}."]`
          );
          elementToScroll?.scrollTo(
            actionType === ActionType.scrollToStart
              ? !reversedValue
                ? 0
                : elementToScroll.scrollWidth
              : reversedValue
              ? 0
              : elementToScroll.scrollWidth,
            actionType === ActionType.scrollToStart
              ? !reversedValue
                ? 0
                : elementToScroll.scrollHeight
              : reversedValue
              ? 0
              : elementToScroll.scrollHeight
          );
        }
      }
    }
  } catch (err: any) {
    alert(readableError(err.message));
  }
};
