import { TableQueryFilterOperator } from "./index";

export const operators = {
  [TableQueryFilterOperator.equal]: "==",
  [TableQueryFilterOperator.notEqual]: "!=",
  [TableQueryFilterOperator.less]: "<",
  [TableQueryFilterOperator.lessOrEqual]: "<=",
  [TableQueryFilterOperator.greater]: ">",
  [TableQueryFilterOperator.greaterOrEqual]: ">=",
  [TableQueryFilterOperator.contains]: "array-contains",
};

export const needOrderByOperators = [
  operators[TableQueryFilterOperator.notEqual],
  operators[TableQueryFilterOperator.less],
  operators[TableQueryFilterOperator.lessOrEqual],
  operators[TableQueryFilterOperator.greater],
  operators[TableQueryFilterOperator.greaterOrEqual],
];
