import firebase from "firebase/compat/app";

import { GetVariableValue } from "../hooks";
import {
  ComponentType,
  addCloseStripeButton,
  currentUserVariableSource,
  showStripe,
} from "../utils";

export const checkoutSelected = async (
  auth: firebase.auth.Auth,
  getVariableValue: GetVariableValue
) =>
  new Promise(async (resolve, reject) => {
    try {
      const product = document
        .querySelector(`[id^='${ComponentType.product}.'][selected="true"]`)
        ?.getAttribute("product") as string;
      const { id, accessLevels = [] } = JSON.parse(product);
      const token = await auth.currentUser?.getIdToken();
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/checkout/sessions`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ productId: id }),
        }
      );
      // @ts-ignore
      const stripe = Stripe(
        process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY
      );
      const { clientSecret } = await res.json();
      const checkout = await stripe.initEmbeddedCheckout({ clientSecret });
      showStripe();
      checkout.mount("#stripe");
      const timer = setInterval(async () => {
        const profileAccessLevelsString = await getVariableValue({
          ...currentUserVariableSource,
          fieldName: "accessLevels",
        });
        const profileAccessLevels = profileAccessLevelsString
          ? JSON.parse(profileAccessLevelsString)
          : [];
        for (const accessLevel of accessLevels) {
          if (!profileAccessLevels.includes(accessLevel)) {
            return;
          }
        }
        close();
      }, 1000);
      const close = () => {
        clearInterval(timer);
        checkout.destroy();
        resolve(true);
      };
      addCloseStripeButton(close);
    } catch (err) {
      reject(err);
    }
  });
