import { SetURLSearchParams } from "react-router-dom";

import { F } from "../context";
import {
  GetVariable,
  setInputParameters,
  setLocalVariables,
  Update,
} from "../hooks";
import {
  BarComponentType,
  BrandingThemeType,
  ComponentType,
  Config,
  createLogo,
  createScreen,
  createScreenCorners,
  createTabBar,
  createTopBar,
  getPx,
  readableError,
  runActions,
  safeAreaBottom,
  safeAreaTop,
  Screen,
  ScreenSize,
  setComponents,
  setTabs,
  startLoader,
  stopLoader,
  tabBarHeight,
  topBarHeight,
} from "./index";

const scroll: { [key: string]: () => void } = {};
const chatsLimits: { [key: string]: number } = {};

export const getScreen = async (
  inDevice: boolean,
  f: F,
  screenConfig: Screen,
  config: Config,
  rootElement: HTMLElement,
  desktopMode: boolean,
  language: string,
  theme: BrandingThemeType,
  launchOpen: boolean,
  setLaunchOpen: React.Dispatch<React.SetStateAction<boolean>>,
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
  navigate: (pathname: string, search?: string) => void,
  navigateBack: () => Promise<void>,
  setPaywallOpen: React.Dispatch<React.SetStateAction<boolean>>,
  getVariable: GetVariable,
  update: Update,
  email?: string,
  token?: string,
  leading?: boolean,
  trailing?: boolean,
  inputParameterValue?: string
) => {
  startLoader();
  const { auth, emailForSignInKey, waitProfile } = f;
  const {
    id,
    screenName,
    size,
    components = [],
    topBar,
    showTopBar,
    showBackButton,
    screenTitle,
    onScreenVisitActions,
    inputParameters,
    localVariables,
  } = screenConfig;
  const {
    screens = [],
    branding: {
      appName,
      icons: { iosIcon },
      colorStyles,
      typography: { iosDefaultFontFamily: fontFamily, fontStyles = [] },
    },
    tabBars = [],
    resources = [],
  } = config;
  const applicationName = appName.locales[language] || "";
  const applicationIcon = resources.find((el) => el.id === iosIcon)?.url || "";
  const tabBar = tabBars.find((el) =>
    el.tabs?.find((el) => el.screen === screenName)
  );
  const tabBarId = `${BarComponentType.tabBar}.${tabBar?.name}.${tabBar?.id}`;
  const topBarId = `${BarComponentType.topBar}.top bar.${id}-${BarComponentType.topBar}`;
  screenConfig.id = inputParameterValue
    ? inputParameterValue + screenName
    : screenName;
  const screenId = `screen.${screenConfig.id}`;
  const customScroll =
    desktopMode &&
    !leading &&
    !trailing &&
    components.length === 1 &&
    (components[0].componentType === ComponentType.scroll ||
      components[0].componentType === ComponentType.list);
  const realSafeAreaTop = inDevice ? 53 : safeAreaTop;
  const realSafeAreaBottom = inDevice ? 33 : safeAreaBottom;
  const getVariableValue = getVariable(screenConfig);
  const runOnScreenVisitActions = () =>
    onScreenVisitActions &&
    runActions(
      id,
      onScreenVisitActions,
      f,
      setSearchParams,
      navigate,
      navigateBack,
      setPaywallOpen,
      screens,
      screenConfig,
      getVariableValue,
      update
    );
  if (inputParameters) {
    await setInputParameters(
      getVariableValue,
      inputParameters,
      searchParams,
      inputParameterValue
    );
  }
  if (localVariables) {
    await setLocalVariables(id, language, getVariableValue, localVariables);
  }
  if (!launchOpen) {
    await runOnScreenVisitActions();
  }
  if (!trailing) {
    rootElement.innerHTML = "";
  }
  const screen = await createScreen(
    screenId,
    screenConfig,
    rootElement,
    colorStyles,
    theme,
    resources,
    getVariableValue,
    size,
    !!tabBar,
    leading,
    trailing
  );
  if (customScroll) {
    rootElement.style.removeProperty("height");
    screen.style.removeProperty("height");
  } else {
    rootElement.style.height = "100%";
  }
  if (desktopMode) {
    createScreenCorners(screen);
    if (!trailing) {
      createLogo(
        screen,
        applicationName,
        applicationIcon,
        colorStyles.onBackground[theme],
        fontFamily,
        size === ScreenSize.compact && !tabBar,
        email || token
          ? undefined
          : () => {
              setLaunchOpen(true);
              setPaywallOpen(false);
              navigate("/");
            }
      );
    }
  }
  const showTopBarValue = showTopBar?.source
    ? (await getVariableValue(showTopBar.source)) === "true"
    : !!showTopBar?.constant;
  const getPxValue = getPx(
    desktopMode ? false : !!tabBar,
    showTopBarValue,
    realSafeAreaTop,
    realSafeAreaBottom
  );
  if (showTopBarValue) {
    await createTopBar(
      topBarId,
      topBarHeight + realSafeAreaTop,
      screen,
      f,
      fontFamily,
      fontStyles,
      colorStyles,
      theme,
      resources,
      desktopMode,
      setSearchParams,
      navigate,
      navigateBack,
      setPaywallOpen,
      screens,
      screenConfig,
      language,
      getPxValue,
      getVariableValue,
      getVariable,
      update,
      topBar,
      showBackButton,
      screenTitle
    );
  }
  if (tabBar) {
    const screenTabBar = await createTabBar(
      tabBarId,
      tabBar,
      tabBarHeight + realSafeAreaBottom,
      screen,
      colorStyles,
      theme,
      resources,
      getVariableValue
    );
    await setTabs(
      screenName,
      tabBar,
      screenTabBar,
      fontFamily,
      colorStyles,
      theme,
      resources,
      desktopMode,
      navigate,
      language,
      getVariableValue
    );
  }
  if (components.length) {
    await setComponents(
      f,
      components,
      screen,
      fontFamily,
      fontStyles,
      colorStyles,
      theme,
      resources,
      customScroll,
      true,
      desktopMode,
      setSearchParams,
      navigate,
      navigateBack,
      setPaywallOpen,
      screens,
      screenConfig,
      language,
      getPxValue,
      getVariableValue,
      getVariable,
      update,
      (func) => (scroll[screenConfig.id] = func),
      chatsLimits[screenConfig.id] || 30,
      (limit) => (chatsLimits[screenConfig.id] = limit)
    );
    scroll[screenConfig.id]?.();
    if (desktopMode && !scroll[screenConfig.id]) {
      window.scrollTo({ top: 0 });
    }
  }
  stopLoader();
  if (launchOpen) {
    await new Promise((resolve) => {
      const unsubscribe = auth.onAuthStateChanged(
        (user) => {
          unsubscribe();
          resolve(user);
        },
        (err) => {
          unsubscribe();
          alert(readableError(err.message));
        }
      );
    });
    if (email && auth.currentUser?.email !== email) {
      window.parent.postMessage("NEED_TOKEN", "*");
      return;
    } else if (token) {
      try {
        await auth.signInWithCustomToken(token);
        await waitProfile(getVariableValue);
      } catch (err: any) {
        alert(readableError(err.message));
      }
    } else if (auth.isSignInWithEmailLink(window.location.href)) {
      const email = localStorage.getItem(emailForSignInKey);
      if (email) {
        try {
          await auth.signInWithEmailLink(email, window.location.href);
          localStorage.removeItem(emailForSignInKey);
          await waitProfile(getVariableValue);
        } catch (err: any) {
          alert(readableError(err.message));
        }
      }
    }
    await runOnScreenVisitActions();
    setLaunchOpen(false);
  }
};
