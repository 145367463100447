import { SetURLSearchParams } from "react-router-dom";

import { F } from "../context";
import { GetVariable, GetVariableValue, Update } from "../hooks";
import {
  BrandingColorStyles,
  BrandingFontStyle,
  BrandingThemeType,
  ComponentType,
  Resource,
  Screen,
  ScreenComponent,
  carouselHandler,
  createElement,
  getComponentTypeById,
  listHandler,
  listItemName,
  listItemNameCopy,
  listSectionHeaderName,
  listSectionHeaderNameCopy,
  overlayHandler,
  progressIndicatorHandler,
  showMoreButtonHandler,
} from "./index";

export const setComponents = async (
  f: F,
  components: ScreenComponent[],
  parent: HTMLElement,
  fontFamily: string,
  fontStyles: BrandingFontStyle[],
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType,
  resources: Resource[],
  customScroll: boolean,
  firstChildOfScreen: boolean,
  desktopMode: boolean,
  setSearchParams: SetURLSearchParams,
  navigate: (pathname: string, search?: string) => void,
  navigateBack: () => Promise<void>,
  setPaywallOpen: React.Dispatch<React.SetStateAction<boolean>>,
  screens: Screen[],
  screenConfig: Screen,
  language: string,
  getPxValue: (val?: string) => string,
  getVariableValue: GetVariableValue,
  getVariable: GetVariable,
  update: Update,
  setScroll: (func: () => void) => void,
  limit: number,
  setLimit: (limit: number) => void
) => {
  const setSubComponents = async (
    element: HTMLElement,
    component: ScreenComponent
  ) => {
    const {
      name,
      componentType,
      subComponents = [],
      listId,
      indexInList,
      numberOfLines,
      showMoreTextButton,
      value,
      duration,
      loop,
      listStyle,
    } = component;
    const isListItem = name === listItemName || name === listItemNameCopy;
    const isListSectionHeader =
      name === listSectionHeaderName || name === listSectionHeaderNameCopy;
    const isOverlay = componentType === ComponentType.overlay;
    const isText = componentType === ComponentType.text;
    const isCarousel = componentType === ComponentType.carousel;
    const isProgressIndicator =
      componentType === ComponentType.progressIndicator;
    const isList = componentType === ComponentType.list;
    await setComponents(
      f,
      subComponents,
      element,
      fontFamily,
      fontStyles,
      colorStyles,
      theme,
      resources,
      false,
      false,
      desktopMode,
      setSearchParams,
      navigate,
      navigateBack,
      setPaywallOpen,
      screens,
      screenConfig,
      language,
      getPxValue,
      isListItem || isListSectionHeader
        ? getVariable(screenConfig, listId, indexInList)
        : getVariableValue,
      getVariable,
      update,
      setScroll,
      limit,
      setLimit
    );
    if (isOverlay) {
      overlayHandler(element);
    } else if (isText) {
      await showMoreButtonHandler(
        element,
        getVariableValue,
        numberOfLines,
        showMoreTextButton
      );
    } else if (isCarousel) {
      await carouselHandler(element, getVariableValue, value, duration, loop);
    } else if (isProgressIndicator) {
      await progressIndicatorHandler(
        element,
        getVariableValue,
        value,
        duration,
        loop
      );
    } else if (isList) {
      listHandler(element, listStyle);
    }
  };
  for (const component of components) {
    const { name, listId, indexInList } = component;
    const isListItem = name === listItemName || name === listItemNameCopy;
    const isListSectionHeader =
      name === listSectionHeaderName || name === listSectionHeaderNameCopy;
    const onlyChild =
      firstChildOfScreen ||
      (!isListItem &&
        !isListSectionHeader &&
        (getComponentTypeById(parent.id) === ComponentType.carousel ||
          components.length === 1));
    await createElement(
      f,
      setSubComponents,
      component,
      parent,
      fontFamily,
      fontStyles,
      colorStyles,
      theme,
      resources,
      customScroll,
      firstChildOfScreen,
      onlyChild,
      desktopMode,
      setSearchParams,
      navigate,
      navigateBack,
      setPaywallOpen,
      screens,
      screenConfig,
      language,
      getPxValue,
      isListItem || isListSectionHeader
        ? getVariable(screenConfig, listId, indexInList)
        : getVariableValue,
      getVariable,
      update,
      setScroll,
      limit,
      setLimit
    );
  }
};
