import { useEffect } from "react";

export const useVersionController = () => {
  useEffect(() => {
    if (!window.location.href.includes("http://localhost")) {
      const getVersion = () =>
        fetch("/version.json")
          .then((response) => response.json())
          .then(
            ({ currentVersion }) =>
              process.env.REACT_APP_RELEASE !== currentVersion &&
              window.confirm("New version available, refresh to update?") &&
              // @ts-ignore
              window.location.reload(true)
          )
          .catch((err) => alert(err));
      getVersion();
      const interval = setInterval(getVersion, 60000);
      return () => {
        clearInterval(interval);
      };
    }
  }, []);
};
