import firebase from "firebase/compat/app";
import { createContext } from "react";

import { GetVariableValue } from "../hooks";
import { AnyVariable, InputParameter } from "../utils";

export type FP = (
  parameters: InputParameter[],
  getVariableValue: GetVariableValue
) => Promise<void>;

export type FV = (
  variable: AnyVariable,
  getVariableValue: GetVariableValue
) => Promise<void>;

export type NFP = (
  name: string,
  parameters: InputParameter[],
  getVariableValue: GetVariableValue
) => Promise<void>;

export interface F {
  firestorePrefix: string;
  auth: firebase.auth.Auth;
  firestore: firebase.firestore.Firestore;
  signInWithGoogle: () => Promise<void>;
  createUserWithEmailAndPassword: FP;
  signInWithEmailAndPassword: FP;
  sendPasswordResetEmail: FV;
  sendSignInLinkToEmail: FV;
  reSendSignInLinkToEmail: FV;
  waitProfile: (getVariableValue: GetVariableValue) => Promise<void>;
  getAssetRecord: (file: any) => Promise<unknown>;
  runFlow: NFP;
  emailForSignInKey: string;
}

export const FirebaseContext = createContext({
  f: {
    firestorePrefix: "",
    auth: {},
    firestore: {},
    signInWithGoogle: () => {},
    createUserWithEmailAndPassword: (parameters, getVariableValue) => {},
    signInWithEmailAndPassword: (parameters, getVariableValue) => {},
    sendPasswordResetEmail: (variable, getVariableValue) => {},
    sendSignInLinkToEmail: (variable, getVariableValue) => {},
    reSendSignInLinkToEmail: (variable, getVariableValue) => {},
    waitProfile: (getVariableValue) => {},
    getAssetRecord: (file) => {},
    runFlow: (name, parameters, getVariableValue) => {},
    emailForSignInKey: "",
  } as F,
});

export * from "./FirebaseProvider";
