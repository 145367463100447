import { generateRefreshKey, GetVariableValue } from "../hooks";
import {
  BooleanVariable,
  BrandingColorStyles,
  BrandingThemeType,
  listEmptyName,
  listItemName,
  listItemNameCopy,
  listSectionHeaderName,
  listSectionHeaderNameCopy,
  ListStyle,
  StringVariable,
  VariableSourceType,
} from "./index";

const getListItems = (list: HTMLElement, reversed?: boolean) => {
  const listItems = Array.from(
    list.querySelectorAll(
      `:scope > [id*="${listItemName}"], :scope > [id*="${listItemNameCopy}"], :scope > [id*="${listSectionHeaderName}"], :scope > [id*="${listSectionHeaderNameCopy}"]`
    )
  ) as HTMLElement[];
  return reversed ? listItems.reverse() : listItems;
};

export const listHandler = (list: HTMLElement, listStyle?: ListStyle) => {
  const listEmpty = list.querySelector(
    `:scope > [id*="${listEmptyName}"]`
  ) as HTMLElement | null;
  const listItems = getListItems(list);
  if (listEmpty) {
    if (listItems.length) {
      listEmpty.hidden = true;
    } else {
      listEmpty.removeAttribute("hidden");
    }
  }
  if (listStyle === ListStyle.gallery) {
    list.style.scrollSnapType = "x mandatory";
    listItems.forEach((el) => (el.style.scrollSnapAlign = "center"));
  }
};

export const listGalleryHandler = async (
  id: string,
  list: HTMLElement,
  getVariableValue: GetVariableValue,
  value?: StringVariable,
  reversed?: BooleanVariable,
  name?: string,
  indexInList?: number
) => {
  const valueRefresh = async () => {
    const valueValue = value?.source
      ? await getVariableValue(value.source, {
          [generateRefreshKey(id, "value", indexInList)]: valueRefresh,
        })
      : value?.constant;
    const reversedValue = reversed?.source
      ? (await getVariableValue(reversed.source, {
          [generateRefreshKey(id, "reversed", indexInList)]: valueRefresh,
        })) === "true"
      : !!reversed?.constant;
    const listItems = getListItems(list, reversedValue);
    const currentValue = valueValue
      ? +valueValue
      : reversedValue
      ? listItems.length - 1
      : 0;
    const listItem = listItems[currentValue];
    if (listItem) {
      const { offsetLeft, offsetTop } = listItem;
      list.scrollTo(offsetLeft, offsetTop);
    }
  };
  await valueRefresh();
  const setValue = async () => {
    const reversedValue = reversed?.source
      ? (await getVariableValue(reversed.source)) === "true"
      : !!reversed?.constant;
    const { x, y } = list.getBoundingClientRect();
    let valueToSet = "0";
    const listItems = getListItems(list, reversedValue);
    for (let i = 0; i < listItems.length; i++) {
      const listItem = listItems[i];
      const { x: iX, y: iY } = listItem.getBoundingClientRect();
      if (x <= iX && y <= iY) {
        valueToSet = String(i);
        break;
      }
    }
    await getVariableValue(
      {
        type: VariableSourceType.component,
        componentName: name,
        fieldName: "value",
      },
      undefined,
      valueToSet
    );
  };
  await setValue();
  list.addEventListener("scroll", setValue);
};

const getArrow = (
  element: HTMLElement,
  onSurfaceVariant: string,
  right?: boolean
) => {
  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute("viewBox", "0 0 11 24");
  svg.setAttribute("fill", "none");
  svg.innerHTML = `<path d="M9 2L2 12L9 22" stroke="${onSurfaceVariant}" stroke-width="3" stroke-linecap="round"/>`;
  svg.style.width = "10px";
  svg.style.height = "100%";
  svg.style.padding = "0 5px";
  svg.style.zIndex = "1";
  svg.style.position = "absolute";
  svg.style.top = "0";
  svg.style[right ? "right" : "left"] = "0";
  svg.style.transform = `rotate(${right ? 180 : 0}deg)`;
  svg.classList.add("clickable");
  svg.onclick = () =>
    (element.scrollLeft = right
      ? element.scrollLeft + element.clientWidth
      : element.scrollLeft - element.clientWidth);
  return svg;
};

export const setListArrows = (
  element: HTMLElement,
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType,
  getVariableValue: GetVariableValue,
  reversed?: BooleanVariable
) => {
  element.style.scrollBehavior = "smooth";
  element.style.marginLeft = element.style.paddingLeft;
  element.style.marginRight = element.style.paddingRight;
  element.style.removeProperty("padding");
  const listWrapper = document.createElement("div");
  listWrapper.style.maxWidth = "100%";
  listWrapper.style.position = "relative";
  element.parentNode?.replaceChild(listWrapper, element);
  listWrapper.appendChild(element);
  const onSurfaceVariant = colorStyles.onSurfaceVariant[theme];
  const leftArrow = getArrow(element, onSurfaceVariant);
  const rightArrow = getArrow(element, onSurfaceVariant, true);
  listWrapper.onmouseover = async () => {
    const reversedValue = reversed?.source
      ? (await getVariableValue(reversed.source)) === "true"
      : !!reversed?.constant;
    const { scrollLeft, clientWidth, scrollWidth } = element;
    if (reversedValue) {
      if (Math.round(-scrollLeft + clientWidth) < scrollWidth) {
        listWrapper.appendChild(leftArrow);
      } else {
        leftArrow.parentNode?.removeChild(leftArrow);
      }
      if (-scrollLeft > 0) {
        listWrapper.appendChild(rightArrow);
      } else {
        rightArrow.parentNode?.removeChild(rightArrow);
      }
    } else {
      if (scrollLeft > 0) {
        listWrapper.appendChild(leftArrow);
      } else {
        leftArrow.parentNode?.removeChild(leftArrow);
      }
      if (Math.round(scrollLeft + clientWidth) < scrollWidth) {
        listWrapper.appendChild(rightArrow);
      } else {
        rightArrow.parentNode?.removeChild(rightArrow);
      }
    }
  };
  listWrapper.onmouseleave = () => {
    leftArrow.parentNode?.removeChild(leftArrow);
    rightArrow.parentNode?.removeChild(rightArrow);
  };
};
