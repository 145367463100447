import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Badge } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import dayjs from "dayjs";
import ReactDOM from "react-dom/client";

import {
  CollectionWithRecords,
  generateRefreshKey,
  GetVariable,
  GetVariableValue,
} from "../hooks";
import {
  BrandingColorStyles,
  BrandingFontStyle,
  BrandingThemeType,
  CalendarStyleConstant,
  ComponentsDirection,
  coordinateHandler,
  getColor,
  getMediaResourceUrl,
  getTextValue,
  ImageLayout,
  ImageLocation,
  MapStyle,
  Resource,
  Screen,
  ScreenComponent,
  startLoader,
  stopLoader,
  VariableSourceType,
} from "./index";

export const createText = async (
  element: HTMLElement,
  component: ScreenComponent,
  language: string,
  getVariableValue: GetVariableValue
) => {
  const { id, indexInList, text } = component;
  const textElement = document.createElement("div");
  const textRefresh = async () => {
    textElement.innerHTML = await getTextValue(
      language,
      getVariableValue,
      { [generateRefreshKey(id, "text", indexInList)]: textRefresh },
      text
    );
  };
  await textRefresh();
  element.appendChild(textElement);
};

export const createButton = async (
  element: HTMLElement,
  component: ScreenComponent,
  resources: Resource[],
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType,
  language: string,
  getVariableValue: GetVariableValue
) => {
  const { id, indexInList, text, image, imageColor, imageLocation } = component;
  element.style.display = "flex";
  element.style.justifyContent = "center";
  element.style.alignItems = "center";
  element.style.gap = "10px";
  element.style.whiteSpace = "nowrap";
  if (image) {
    const width = 25;
    const imageElement = document.createElement("img");
    imageElement.style.width = `${width}px`;
    imageElement.style.height = `${width}px`;
    imageElement.style.maxHeight = "100%";
    imageElement.style.objectFit = "contain";
    const imageRefresh = async () => {
      const url = await getMediaResourceUrl(
        resources,
        width * 3,
        getVariableValue,
        { [generateRefreshKey(id, "image", indexInList)]: imageRefresh },
        image,
        imageColor,
        theme,
        colorStyles
      );
      imageElement.setAttribute("src", url);
    };
    await imageRefresh();
    element.appendChild(imageElement);
  }
  if (text) {
    const textElement = document.createElement("div");
    if (
      imageLocation !== ImageLocation.leadingAttached &&
      imageLocation !== ImageLocation.trailingAttached
    ) {
      textElement.style.width = "100%";
    }
    if (
      imageLocation === ImageLocation.trailing ||
      imageLocation === ImageLocation.trailingAttached
    ) {
      element.style.flexDirection = "row-reverse";
    }
    if (imageLocation === ImageLocation.top) {
      element.style.flexDirection = "column";
    }
    if (imageLocation === ImageLocation.bottom) {
      element.style.flexDirection = "column-reverse";
    }
    textElement.style.overflow = "hidden";
    textElement.style.whiteSpace = "nowrap";
    textElement.style.textOverflow = "ellipsis";
    const textRefresh = async () => {
      textElement.innerHTML = await getTextValue(
        language,
        getVariableValue,
        { [generateRefreshKey(id, "text", indexInList)]: textRefresh },
        text
      );
    };
    await textRefresh();
    element.appendChild(textElement);
  }
};

export const emptyBackground =
  "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzE0OTc3XzM4MjM4KSI+CjxwYXRoIGQ9Ik0wIDBMNTAgNTAiIHN0cm9rZT0iI0RERTJFRSIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzE0OTc3XzM4MjM4Ij4KPHJlY3Qgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=)";
export const createImage = async (
  element: HTMLElement,
  component: ScreenComponent,
  resources: Resource[],
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType,
  getVariableValue: GetVariableValue
) => {
  element.style.display = "flex";
  const { id, indexInList, image, imageColor, imageLayout } = component;
  const imageElement = document.createElement("img");
  imageElement.style.width = "100%";
  imageElement.style.height = "100%";
  imageElement.style.borderRadius = "inherit";
  if (imageLayout === ImageLayout.fill) {
    imageElement.style.objectFit = "cover";
  } else {
    imageElement.style.objectFit = "contain";
  }
  const imageRefresh = async () => {
    const url = await getMediaResourceUrl(
      resources,
      element.getBoundingClientRect().width,
      getVariableValue,
      { [generateRefreshKey(id, "image", indexInList)]: imageRefresh },
      image,
      imageColor,
      theme,
      colorStyles
    );
    imageElement.setAttribute("src", url);
  };
  await imageRefresh();
  element.appendChild(imageElement);
  //   element.innerHTML = `
  //   <i style="display: block; position: relative; pointer-events: none; overflow: hidden; width: 100%; height: 100%; background: ${emptyBackground};">
  //     <i style="font-style: normal; font-size: 10px; color: #7F899E; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">Image</i>
  //   </i>
  // `
};

export const createVideo = async (
  element: HTMLElement,
  component: ScreenComponent,
  resources: Resource[],
  getVariableValue: GetVariableValue
) => {
  const { id, indexInList, video, autoplay } = component;
  if (video) {
    const videoElement = document.createElement("video");
    videoElement.controls = true;
    videoElement.playsInline = true;
    if (autoplay?.constant) {
      videoElement.autoplay = true;
    }
    videoElement.style.width = "100%";
    videoElement.style.height = "100%";
    const videoRefresh = async () => {
      const url = await getMediaResourceUrl(
        resources,
        0,
        getVariableValue,
        { [generateRefreshKey(id, "video", indexInList)]: videoRefresh },
        video
      );
      videoElement.innerHTML = `<source src="${url}" />`;
    };
    await videoRefresh();
    element.appendChild(videoElement);
  }
};

export const listItemName = "List Item";
export const listItemNameCopy = `${listItemName} Copy`;
export const listEmptyName = "List Empty";
export const listHeaderName = "List Header";
export const listFooterName = "List Footer";
export const listSectionHeaderName = "Section Header";
export const listSectionHeaderNameCopy = `${listSectionHeaderName} Copy`;
export const createList = async (
  element: HTMLElement,
  component: ScreenComponent,
  screenConfig: Screen,
  getVariable: GetVariable,
  getVariableValue: GetVariableValue,
  setSubComponents: (element: HTMLElement, component: ScreenComponent) => void,
  limit: number,
  setLimit: (limit: number) => void
) => {
  const {
    id,
    indexInList,
    listItems,
    listItemContextKey,
    subComponents = [],
    componentsDirection,
    listItemSectionKey,
  } = component;
  const detectLoadMore = async () => {
    const {
      clientWidth,
      clientHeight,
      scrollLeft,
      scrollTop,
      scrollWidth,
      scrollHeight,
    } = element;
    if (
      componentsDirection === ComponentsDirection.vertical
        ? clientHeight + Math.abs(scrollTop) + 10 > scrollHeight
        : clientWidth + Math.abs(scrollLeft) + 10 > scrollWidth
    ) {
      startLoader();
      const newLimit = limit + 30;
      setLimit(newLimit);
      element.removeEventListener("scroll", detectLoadMore);
      await createList(
        element,
        component,
        screenConfig,
        getVariable,
        getVariableValue,
        setSubComponents,
        newLimit,
        setLimit
      );
      stopLoader();
    }
  };
  const refresh = async () => {
    element.removeEventListener("scroll", detectLoadMore);
    await createList(
      element,
      component,
      screenConfig,
      getVariable,
      getVariableValue,
      setSubComponents,
      limit,
      setLimit
    );
  };
  const listId = id;
  if (listItems?.source && listItemContextKey) {
    const value = (await getVariableValue(
      listItems.source,
      { [generateRefreshKey(id, "listItems", indexInList)]: refresh },
      undefined,
      undefined,
      { listId, listItemContextKey, limit }
    )) as CollectionWithRecords | undefined;
    const listSize = value?.records.length;
    const listItem = subComponents.find((el) => el.name === listItemName);
    const listSectionHeader = subComponents.find(
      (el) => el.name === listSectionHeaderName
    );
    const components: ScreenComponent[] = [];
    if (listItem) {
      if (listSize) {
        for (let indexInList = 0; indexInList < listSize; indexInList++) {
          components.push({
            ...listItem,
            name: listItemNameCopy,
            listId,
            indexInList,
          });
          if (listSectionHeader && listItemSectionKey?.source) {
            const curr = await getVariable(
              screenConfig,
              listId,
              indexInList
            )(listItemSectionKey.source);
            const next = await getVariable(
              screenConfig,
              listId,
              indexInList + 1
            )(listItemSectionKey.source);
            if (curr !== next) {
              components.push({
                ...listSectionHeader,
                name: listSectionHeaderNameCopy,
                listId,
                indexInList,
              });
            }
          }
        }
      }
    }
    const scrollLeft = element.scrollLeft;
    const scrollTop = element.scrollTop;
    element.innerHTML = "";
    await setSubComponents(element, {
      ...component,
      subComponents: [
        ...subComponents.filter(
          (el) => el.name !== listItemName && el.name !== listSectionHeaderName
        ),
        ...components,
      ],
    });
    element.scrollLeft = scrollLeft;
    element.scrollTop = scrollTop;
    element.dispatchEvent(new Event("scroll"));
    if (limit === listSize) {
      detectLoadMore();
      element.addEventListener("scroll", detectLoadMore);
    }
  }
};

export const createSpacer = (element: HTMLElement, hasSize: boolean) => {
  if (!hasSize) {
    element.style.flexGrow = "1";
  }
};

export const changeThemeEvent = "changeThemeEvent";
export const createThemePicker = (
  element: HTMLElement,
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType
) => {
  const primary = colorStyles.primary[theme];
  const surfaceVariant = colorStyles.surfaceVariant[theme];
  const onSurfaceVariant = colorStyles.onSurfaceVariant[theme];
  const outline = colorStyles.outline[theme];
  const themePickerElement = document.createElement("div");
  themePickerElement.style.width = "100%";
  themePickerElement.style.height = "100%";
  themePickerElement.style.display = "flex";
  themePickerElement.innerHTML = `
  <svg width="100%" viewBox="0 0 358 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g style="cursor: pointer;" onclick="document.dispatchEvent(new CustomEvent('${changeThemeEvent}', { detail: ${
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  }}))">
      <rect x="0.5" y="0.5" width="173" height="99" rx="9.5" fill="${surfaceVariant}" stroke="${surfaceVariant}"/>
      <text fill="${onSurfaceVariant}" xml:space="preserve" style="white-space: pre" font-size="13" letter-spacing="-0.078px"><tspan x="16" y="42.6211">System</tspan></text>
      <text fill="${outline}" xml:space="preserve" style="white-space: pre" font-size="13" letter-spacing="-0.078px"><tspan x="16" y="65.6211">The same as on </tspan><tspan x="16" y="81.6211">the device</tspan></text>
    </g>
    <g style="cursor: pointer;" onclick="document.dispatchEvent(new CustomEvent('${changeThemeEvent}', { detail: true }))">
      <rect x="186" y="0.5" width="80" height="99" rx="9.5" fill="${surfaceVariant}" stroke="${
    theme === BrandingThemeType.dark ? primary : surfaceVariant
  }"/>
      <path d="M235.085 40.79C234.928 42.4922 234.289 44.1144 233.243 45.4668C232.197 46.8192 230.788 47.8458 229.181 48.4265C227.573 49.0073 225.833 49.1181 224.164 48.7461C222.496 48.3741 220.968 47.5345 219.759 46.3258C218.55 45.117 217.711 43.589 217.339 41.9205C216.967 40.252 217.078 38.5121 217.658 36.9043C218.239 35.2965 219.266 33.8874 220.618 32.8418C221.97 31.7961 223.593 31.1573 225.295 31C224.298 32.3483 223.819 34.0094 223.943 35.6814C224.068 37.3534 224.789 38.9251 225.974 40.1106C227.16 41.2961 228.731 42.0168 230.403 42.1415C232.075 42.2662 233.737 41.7866 235.085 40.79Z" fill="${
        theme === BrandingThemeType.dark ? onSurfaceVariant : outline
      }" stroke="${
    theme === BrandingThemeType.dark ? onSurfaceVariant : outline
  }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <text fill="${onSurfaceVariant}" xml:space="preserve" style="white-space: pre" font-size="13" letter-spacing="-0.078px"><tspan x="211.803" y="81.6211">Dark</tspan></text>
   </g>
    <g style="cursor: pointer;" onclick="document.dispatchEvent(new CustomEvent('${changeThemeEvent}', { detail: false }))">
      <rect x="278.5" y="0.5" width="79" height="99" rx="9.5" fill="${surfaceVariant}" stroke="${
    theme === BrandingThemeType.light ? primary : surfaceVariant
  }"/>
      <path d="M318 45C320.761 45 323 42.7614 323 40C323 37.2386 320.761 35 318 35C315.239 35 313 37.2386 313 40C313 42.7614 315.239 45 318 45Z" fill="${
        theme === BrandingThemeType.dark ? outline : onSurfaceVariant
      }" stroke="${
    theme === BrandingThemeType.dark ? outline : onSurfaceVariant
  }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M318 29V31" stroke="${
        theme === BrandingThemeType.dark ? outline : onSurfaceVariant
      }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M318 49V51" stroke="${
        theme === BrandingThemeType.dark ? outline : onSurfaceVariant
      }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M310.22 32.2197L311.64 33.6397" stroke="${
        theme === BrandingThemeType.dark ? outline : onSurfaceVariant
      }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M324.36 46.3604L325.78 47.7804" stroke="${
        theme === BrandingThemeType.dark ? outline : onSurfaceVariant
      }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M307 40H309" stroke="${
        theme === BrandingThemeType.dark ? outline : onSurfaceVariant
      }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M327 40H329" stroke="${
        theme === BrandingThemeType.dark ? outline : onSurfaceVariant
      }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M310.22 47.7804L311.64 46.3604" stroke="${
        theme === BrandingThemeType.dark ? outline : onSurfaceVariant
      }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> 
      <path d="M324.36 33.6397L325.78 32.2197" stroke="${
        theme === BrandingThemeType.dark ? outline : onSurfaceVariant
      }" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <text fill="${onSurfaceVariant}" xml:space="preserve" style="white-space: pre" font-size="13" letter-spacing="-0.078px"><tspan x="302.706" y="81.6211">Light</tspan></text>
    </g>
  </svg>
  `;
  element.appendChild(themePickerElement);
};

export const createNotificationsSettings = (
  element: HTMLElement,
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType
) => {
  const primary = colorStyles.primary[theme];
  const onPrimary = colorStyles.onPrimary[theme];
  const surfaceVariant = colorStyles.surfaceVariant[theme];
  const onSurfaceVariant = colorStyles.onSurfaceVariant[theme];
  const outline = colorStyles.outline[theme];
  const notificationsSettingsElement = document.createElement("div");
  notificationsSettingsElement.style.width = "100%";
  notificationsSettingsElement.style.height = "100%";
  notificationsSettingsElement.style.display = "flex";
  notificationsSettingsElement.innerHTML = `
  <svg width="100%" viewBox="0 0 358 160" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="358" height="160" rx="10" fill="${surfaceVariant}"/>
    <text fill="${onSurfaceVariant}" xml:space="preserve" style="white-space: pre" font-size="15" letter-spacing="-0.24px" font-weight="600"><tspan x="69" y="30.832">Enable push notification</tspan></text>
    <text fill="${outline}" xml:space="preserve" style="white-space: pre" font-size="13" letter-spacing="-0.078px"><tspan x="69" y="52.6211">Lorem ipsum, or lipsum as it is sometimes </tspan><tspan x="69" y="68.6211">known, is dummy text used in laying out </tspan><tspan x="69" y="84.6211">print, graphic or web designs.</tspan></text>
    <rect x="68" y="108" width="180" height="30" rx="15" fill="${primary}"/>
    <text fill="${onPrimary}" xml:space="preserve" style="white-space: pre" font-size="15" letter-spacing="-0.24px" font-weight="600"><tspan x="112.001" y="127.332">Go to Settings</tspan></text>
    <path d="M35.1817 36C38.8642 36 41.8273 38.8977 41.8273 42.4752V44.3152C43.8941 44.9604 45.4 46.8314 45.4 49.0661V54.9904C45.4 57.7569 43.1064 60 40.2787 60H30.1226C27.2936 60 25 57.7569 25 54.9904V49.0661C25 46.8314 26.5071 44.9604 28.5727 44.3152V42.4752C28.5849 38.8977 31.548 36 35.1817 36ZM35.1939 49.6611C34.6086 49.6611 34.1331 50.1262 34.1331 50.6986V53.3459C34.1331 53.9302 34.6086 54.3953 35.1939 54.3953C35.7914 54.3953 36.2669 53.9302 36.2669 53.3459V50.6986C36.2669 50.1262 35.7914 49.6611 35.1939 49.6611ZM35.2061 38.0869C32.7308 38.0869 30.7188 40.0425 30.7066 42.4514V44.0564H39.6934V42.4752C39.6934 40.0545 37.6814 38.0869 35.2061 38.0869Z" fill="${primary}"/>
  </svg>
  `;
  element.appendChild(notificationsSettingsElement);
};

// export const createProduct = async (
//   element: HTMLElement,
//   component: ScreenComponent,
//   product: Product,
//   colorStyles: BrandingColorStyles,
//   theme: BrandingThemeType,
//   getPxValue: (val?: string) => string,
//   getVariableValue: GetVariableValue
// ) => {
//   const { selected, borderWidth, backgroundColor, borderColor } = component;
//   const { name, description, tier } = product;
//   const selectedValue = selected?.source
//     ? (await getVariableValue(selected.source)) === "true"
//     : selected?.constant;
//   const borderWidthValue = borderWidth?.source
//     ? await getVariableValue(borderWidth.source)
//     : getPxValue(borderWidth?.constant) || "2";
//   const backgroundColorValue = backgroundColor
//     ? await getColor(backgroundColor, colorStyles, theme, getVariableValue)
//     : "transparent";
//   const borderColorValue = borderColor
//     ? await getColor(borderColor, colorStyles, theme, getVariableValue)
//     : "transparent";

//   element.style.display = "flex";
//   element.style.flexDirection = "column";
//   element.style.justifyContent = "center";
//   element.style.gap = "5px";
//   element.style.minHeight = "70px";
//   element.style.padding = "11px 16px";
//   element.style.borderRadius = "16px";
//   element.style.borderWidth = `${borderWidthValue}px`;
//   element.style.borderStyle = "solid";
//   element.style.borderColor = "transparent";

//   const titleWrapperElement = document.createElement("div");
//   titleWrapperElement.style.display = "flex";
//   titleWrapperElement.style.gap = "5px";
//   titleWrapperElement.style.fontSize = "20px";
//   titleWrapperElement.style.letterSpacing = "0.38px";
//   titleWrapperElement.style.lineHeight = "24px";
//   titleWrapperElement.style.color = borderColorValue;
//   element.appendChild(titleWrapperElement);

//   const titleElement = document.createElement("div");
//   titleElement.style.fontWeight = "600";
//   titleElement.innerHTML = name;
//   titleWrapperElement.appendChild(titleElement);

//   const priceElement = document.createElement("div");
//   priceElement.innerHTML = tier;
//   titleWrapperElement.appendChild(priceElement);

//   const checkMarkElement = document.createElement("div");
//   checkMarkElement.className = "checkMarkElement";
//   checkMarkElement.innerHTML = `
//     <svg width="14" height="9" viewBox="0 0 14 9" fill="none">
//       <path d="M2.44812 4.37011L5.07726 7.01183C5.46817 7.40461 6.10394 7.40461 6.49484 7.01183L11.5516 1.93091" stroke="${backgroundColorValue}" stroke-width="3" stroke-linecap="round" />
//     </svg>`;
//   checkMarkElement.style.display = "flex";
//   checkMarkElement.style.justifyContent = "center";
//   checkMarkElement.style.alignItems = "center";
//   checkMarkElement.style.height = "24px";
//   checkMarkElement.style.width = "24px";
//   checkMarkElement.style.borderRadius = "50%";
//   checkMarkElement.style.border = `1.5px solid ${borderColorValue}`;
//   checkMarkElement.style.position = "absolute";
//   checkMarkElement.style.top = "23px";
//   checkMarkElement.style.right = "16px";
//   element.appendChild(checkMarkElement);

//   if (description) {
//     const descriptionElement = document.createElement("div");
//     descriptionElement.style.fontSize = "14px";
//     descriptionElement.style.fontWeight = "400";
//     descriptionElement.style.lineHeight = "21px";
//     descriptionElement.style.color = borderColorValue;
//     descriptionElement.innerHTML = description;
//     element.appendChild(descriptionElement);
//   }

//   const selectedAttribute = "selected";
//   if (selectedValue) {
//     element.setAttribute(selectedAttribute, "true");
//   }

//   const style = document.createElement("style");
//   style.innerHTML = `
//     div[id="${element.id}"][${selectedAttribute}="true"] {
//       border-color: ${borderColorValue} !important;
//     }
//     div[id="${element.id}"][${selectedAttribute}="true"] > .checkMarkElement {
//       background: ${borderColorValue};
//     }`;
//   element.appendChild(style);

//   element.setAttribute("product", JSON.stringify(product));
//   element.style.cursor = "pointer";
//   element.onclick = () =>
//     document
//       .querySelectorAll(`[id^='${ComponentType.product}.']`)
//       .forEach((el) => {
//         el.removeAttribute(selectedAttribute);
//         element.setAttribute(selectedAttribute, "true");
//       });
// };

export const createTextInput = async (
  parent: HTMLElement,
  element: HTMLElement,
  component: ScreenComponent,
  fontStyles: BrandingFontStyle[],
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType,
  isDatePicker: boolean,
  language: string,
  getVariableValue: GetVariableValue
) => {
  const {
    id,
    indexInList,
    name,
    text,
    date,
    placeholderText,
    placeholderTextFont,
    placeholderTextColor,
    textInputType,
  } = component;
  if (parent.style.flexDirection === "row") {
    element.style.flexGrow = "1";
  }
  const input = document.createElement("input");
  const textRefresh = async () => {
    input.value =
      (text
        ? await getTextValue(
            language,
            getVariableValue,
            { [generateRefreshKey(id, "text", indexInList)]: textRefresh },
            text
          )
        : date?.source
        ? await getVariableValue(date.source, {
            [generateRefreshKey(id, "date", indexInList)]: textRefresh,
          })
        : date?.constant) || "";
  };
  await textRefresh();
  const placeholderTextRefresh = async () => {
    input.placeholder = await getTextValue(
      language,
      getVariableValue,
      {
        [generateRefreshKey(id, "placeholderText", indexInList)]:
          placeholderTextRefresh,
      },
      placeholderText
    );
  };
  await placeholderTextRefresh();
  input.style.font = "inherit";
  input.style.color = "inherit";
  input.style.borderRadius = "inherit";
  input.style.outline = "none";
  input.style.boxSizing = "border-box";
  input.style.width = "100%";
  input.style.height = "100%";
  input.style.borderTop = "none";
  input.style.borderBottom = "none";
  input.style.borderLeft = "16px solid transparent";
  input.style.borderRight = "16px solid transparent";
  input.style.background = "transparent";
  if (textInputType) {
    input.type = textInputType;
  } else if (isDatePicker) {
    input.type = "date";
  }
  const source = {
    type: VariableSourceType.component,
    componentName: name,
    fieldName: isDatePicker ? "date" : "text",
  };
  const setValue = (input: HTMLInputElement) =>
    getVariableValue(source, undefined, input.value);
  await setValue(input);
  let changed = false;
  input.oninput = async (e) => {
    await setValue(e.target as HTMLInputElement);
    if (!changed) {
      const refresh = async () =>
        (input.value = await getVariableValue(source, {
          [generateRefreshKey(id, isDatePicker ? "date" : "text", indexInList)]:
            refresh,
        }));
      await refresh();
    }
    changed = true;
  };
  element.appendChild(input);

  const style = document.createElement("style");
  const currentPlaceholderFont = fontStyles.find(
    (el) => el.styleName === placeholderTextFont?.slice(1)
  )?.ios;
  style.innerHTML = `
    div[id="${element.id}"] > input::placeholder {
      ${
        currentPlaceholderFont &&
        `font-size: ${currentPlaceholderFont.fontSize}px;
        font-weight: ${currentPlaceholderFont.fontWeight};
        font-style: ${currentPlaceholderFont.fontStyle};
        letter-spacing: ${currentPlaceholderFont.letterSpacing}px;
        line-height: ${currentPlaceholderFont.lineHeight}%;`
      }
      ${
        placeholderTextColor &&
        `color: ${await getColor(
          placeholderTextColor,
          colorStyles,
          theme,
          getVariableValue
        )}`
      }
    }`;
  element.appendChild(style);
};

export const createCarousel = async (
  element: HTMLElement,
  component: ScreenComponent,
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType,
  getVariableValue: GetVariableValue
) => {
  element.style.display = "flex";
  const { id, subComponents = [], duration, loop } = component;
  const primary = colorStyles.primary[theme];
  const surfaceVariant = colorStyles.surfaceVariant[theme];
  const slidesLength = subComponents.length;
  if (slidesLength) {
    const loopValue = loop?.source
      ? (await getVariableValue(loop.source)) === "true"
      : !!loop?.constant;
    createProgress(
      id,
      element,
      primary,
      surfaceVariant,
      slidesLength,
      duration,
      loopValue,
      true
    );
  }
};

export const createMap = async (
  element: HTMLElement,
  component: ScreenComponent,
  screenConfig: Screen,
  getVariable: GetVariable,
  getVariableValue: GetVariableValue
) => {
  const {
    id,
    mapStyle,
    showUserLocation,
    coordinate,
    zoom,
    listItems,
    listItemContextKey,
    listItemCoordinate,
  } = component;
  const zoomValue = zoom?.source
    ? await getVariableValue(zoom.source)
    : zoom?.constant;
  const showUserLocationValue = showUserLocation?.source
    ? (await getVariableValue(showUserLocation.source)) === "true"
    : !!showUserLocation?.constant;
  const listId = id;
  const mapTypeId =
    !mapStyle || mapStyle === MapStyle.standard ? "roadmap" : mapStyle;
  const coordinates: {
    label: string;
    position: { lat: number; lng: number };
  }[] = [];
  const center = { lat: 0, lng: 0 };
  if (coordinate?.source) {
    const coordinateValue = await getVariableValue(coordinate.source);
    if (coordinateValue) {
      const parsed = JSON.parse(coordinateValue);
      if (Array.isArray(parsed)) {
        parsed.forEach((el, i) =>
          coordinates.push({
            label: `C${i}`,
            position: coordinateHandler(el, center),
          })
        );
      } else {
        coordinates.push({
          label: "C",
          position: coordinateHandler(parsed, center),
        });
      }
    }
  } else if (coordinate?.constant) {
    coordinates.push({
      label: "C",
      position: coordinateHandler(coordinate.constant, center),
    });
  }
  const mapElement = document.createElement("div");
  mapElement.style.display = "flex";
  mapElement.style.width = "100%";
  mapElement.style.height = "100%";
  const map = new google.maps.Map(mapElement, {
    mapTypeId,
    center,
    zoom: zoomValue ? +zoomValue : 1,
    disableDefaultUI: true,
  });
  if (showUserLocationValue) {
    await navigator.geolocation.getCurrentPosition((position) => {
      const center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      map.setCenter(center);
      coordinates.push({ label: "ME", position: center });
    });
  }
  if (listItems?.source && listItemContextKey && listItemCoordinate?.source) {
    const value = (await getVariableValue(
      listItems.source,
      undefined,
      undefined,
      undefined,
      { listId, listItemContextKey }
    )) as CollectionWithRecords | undefined;
    const listSize = value?.records.length;
    if (listSize) {
      for (let indexInList = 0; indexInList < listSize; indexInList++) {
        const value = await getVariable(
          screenConfig,
          listId,
          indexInList
        )(listItemCoordinate.source);
        if (value) {
          const parsed = JSON.parse(value);
          if (Array.isArray(parsed)) {
            parsed.forEach((el, i) =>
              coordinates.push({
                label: `I${indexInList}I${i}`,
                position: coordinateHandler(el, { lat: 0, lng: 0 }),
              })
            );
          } else {
            coordinates.push({
              label: `I${indexInList}`,
              position: coordinateHandler(parsed, { lat: 0, lng: 0 }),
            });
          }
        }
      }
    }
  }
  const markers = coordinates.map((el) => new google.maps.Marker(el));
  new MarkerClusterer({ markers, map });
  element.appendChild(mapElement);
};

export const createCalendar = async (
  element: HTMLElement,
  component: ScreenComponent,
  fontStyles: BrandingFontStyle[],
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType,
  screenConfig: Screen,
  getVariable: GetVariable,
  getVariableValue: GetVariableValue
) => {
  const {
    id,
    calendarStyle,
    date,
    displayDate,
    headerFont,
    headerColor,
    showHeader,
    showWeekdays,
    listItems,
    listItemContextKey,
    listItemDate,
  } = component;
  const listId = id;
  const primary = colorStyles.primary[theme];
  const onPrimary = colorStyles.onPrimary[theme];
  const calendar = document.createElement("div");
  element.appendChild(calendar);
  const calendarStyleValue = (
    calendarStyle?.source
      ? await getVariableValue(calendarStyle.source)
      : calendarStyle?.constant
  ) as CalendarStyleConstant;
  const values: dayjs.Dayjs[] = [];
  const dateValue = date?.source
    ? await getVariableValue(date.source)
    : date?.constant;
  if (dateValue) {
    values.push(dayjs(dateValue));
  }
  const displayDateValue = displayDate?.source
    ? await getVariableValue(displayDate.source)
    : displayDate?.constant;
  if (displayDateValue) {
    values.push(dayjs(displayDateValue));
  }
  if (listItems?.source && listItemContextKey && listItemDate?.source) {
    const value = (await getVariableValue(
      listItems.source,
      undefined,
      undefined,
      undefined,
      { listId, listItemContextKey }
    )) as CollectionWithRecords | undefined;
    const listSize = value?.records.length;
    if (listSize) {
      for (let indexInList = 0; indexInList < listSize; indexInList++) {
        const value = await getVariable(
          screenConfig,
          listId,
          indexInList
        )(listItemDate.source);
        if (value) {
          values.push(dayjs(value));
        }
      }
    }
  }
  const currentHeaderFont = fontStyles.find(
    (el) => el.styleName === headerFont?.slice(1)
  )?.ios;
  if (calendarStyleValue) {
    const showHeaderValue = showHeader?.source
      ? (await getVariableValue(showHeader.source)) === "true"
      : !!showHeader?.constant;
    const showWeekdaysValue = showWeekdays?.source
      ? (await getVariableValue(showWeekdays.source)) === "true"
      : !!showWeekdays?.constant;
    ReactDOM.createRoot(calendar).render(
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          showDaysOutsideCurrentMonth
          slots={{
            day: (props: any) => (
              <Badge
                overlap="circular"
                badgeContent={
                  !!values.find(
                    ({ $D, $M, $y }: any) =>
                      $D === props.day.$D &&
                      $M === props.day.$M &&
                      $y === props.day.$y
                  )
                    ? "🌚"
                    : undefined
                }
              >
                <PickersDay {...props} />
              </Badge>
            ),
          }}
          sx={{
            width: "auto !important",
            height: "auto !important",
            "& *": { color: "inherit !important", font: "inherit !important" },
            "& div.MuiPickersCalendarHeader-root": {
              display: !showHeaderValue ? "none !important" : undefined,
              padding: "unset !important",
              margin: "8px 0 8px 20px !important",
              color: headerColor
                ? `${await getColor(
                    headerColor,
                    colorStyles,
                    theme,
                    getVariableValue
                  )} !important`
                : undefined,
              fontSize: currentHeaderFont
                ? `${currentHeaderFont.fontSize}px !important`
                : undefined,
              fontWeight: currentHeaderFont
                ? `${currentHeaderFont.fontWeight} !important`
                : undefined,
              fontStyle: currentHeaderFont
                ? `${currentHeaderFont.fontStyle} !important`
                : undefined,
              letterSpacing: currentHeaderFont
                ? `${currentHeaderFont.letterSpacing}px !important`
                : undefined,
              lineHeight: currentHeaderFont
                ? `${currentHeaderFont.lineHeight}% !important`
                : undefined,
            },
            "& div.MuiYearCalendar-root": {
              width: "auto !important",
            },
            "& div.MuiDayCalendar-header": {
              display: !showWeekdaysValue ? "none !important" : undefined,
            },
            '& div[role="row"]': {
              justifyContent: "space-between !important",
            },
            '& button[aria-current="date"], & button[aria-selected="true"], & button[aria-checked="true"]':
              {
                color: `${onPrimary} !important`,
                borderColor: `${primary} !important`,
                background: `${primary} !important`,
              },
            "& button.MuiPickersDay-dayOutsideMonth": {
              opacity: "0.6 !important",
            },
          }}
        />
      </LocalizationProvider>
    );
  }
};

export const createSlider = async (
  element: HTMLElement,
  component: ScreenComponent,
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType,
  getVariableValue: GetVariableValue
) => {
  element.style.display = "flex";
  const {
    id,
    indexInList,
    name,
    minimumValue,
    maximumValue,
    value,
    discrete,
    primaryColor,
    secondaryColor,
    headerColor,
  } = component;
  const input = document.createElement("input");
  const valueRefresh = async () => {
    input.value =
      (value?.source
        ? await getVariableValue(value.source, {
            [generateRefreshKey(id, "value", indexInList)]: valueRefresh,
          })
        : value?.constant) || "0";
  };
  await valueRefresh();
  const minimumValueValue = minimumValue?.source
    ? await getVariableValue(minimumValue.source)
    : minimumValue?.constant;
  const maximumValueValue = maximumValue?.source
    ? await getVariableValue(maximumValue.source)
    : maximumValue?.constant;
  const discreteValue = discrete?.source
    ? (await getVariableValue(discrete.source)) === "true"
    : !!discrete?.constant;
  const trackColor = primaryColor
    ? await getColor(primaryColor, colorStyles, theme, getVariableValue)
    : colorStyles.primary[theme];
  const railColor = secondaryColor
    ? await getColor(secondaryColor, colorStyles, theme, getVariableValue)
    : colorStyles.neutral[theme];
  const thumbColor = headerColor
    ? await getColor(headerColor, colorStyles, theme, getVariableValue)
    : colorStyles.background[theme];

  input.min = minimumValueValue || "0";
  input.max = maximumValueValue || "1";
  input.type = "range";
  if (discreteValue) {
    input.step = "1";
  }
  const source = {
    type: VariableSourceType.component,
    componentName: name,
    fieldName: "value",
  };
  const setValue = (input: HTMLInputElement) =>
    getVariableValue(source, undefined, input.value);
  await setValue(input);
  let changed = false;
  input.oninput = async (e) => {
    await setValue(e.target as HTMLInputElement);
    if (!changed) {
      const refresh = async () =>
        (input.value =
          (await getVariableValue(source, {
            [generateRefreshKey(id, "value", indexInList)]: refresh,
          })) || "0");
      await refresh();
    }
    changed = true;
  };
  element.appendChild(input);

  const style = document.createElement("style");
  const trackStyle = `
    background: ${trackColor};`;
  const thumbStyle = `
      appearance: none;
      width: 28px;
      height: 28px;
      margin-top: -12px;
      border-radius: 50%;
      background: ${thumbColor};
      box-shadow: 0 0 2px 0 ${colorStyles.neutral[theme]};
      cursor: pointer;`;
  const railStyle = `
      height: 4px;
      border-radius: 4px;
      background: ${railColor};`;
  style.innerHTML = `
    div[id="${element.id}"] > input {
      appearance: none;
      background: none;
      width: 100%;
      height: 28px;
      margin: 0;
    }
    div[id="${element.id}"] > input::-moz-range-progress { ${trackStyle}
    }
    div[id="${element.id}"] > input::-webkit-slider-thumb { ${thumbStyle}
    }
    div[id="${element.id}"] > input::-moz-range-thumb { ${thumbStyle}
    }
    div[id="${element.id}"] > input::-webkit-slider-runnable-track { ${railStyle}
    }
    div[id="${element.id}"] > input::-moz-range-track { ${railStyle}
    }`;
  element.appendChild(style);
};

export const createToggle = async (
  element: HTMLElement,
  component: ScreenComponent,
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType,
  getVariableValue: GetVariableValue
) => {
  element.style.display = "flex";
  const { id, indexInList, name, selected, primaryColor, headerColor } =
    component;
  const trackColor = primaryColor
    ? await getColor(primaryColor, colorStyles, theme, getVariableValue)
    : colorStyles.primary[theme];
  const thumbColor = headerColor
    ? await getColor(headerColor, colorStyles, theme, getVariableValue)
    : colorStyles.background[theme];
  const selectedValue = selected?.source
    ? (await getVariableValue(selected.source)) === "true"
    : !!selected?.constant;

  const label = document.createElement("label");
  element.appendChild(label);

  const input = document.createElement("input");
  input.checked = selectedValue;
  input.type = "checkbox";
  const source = {
    type: VariableSourceType.component,
    componentName: name,
    fieldName: "selected",
  };
  const setValue = (input: HTMLInputElement) =>
    getVariableValue(source, undefined, String(input.checked));
  await setValue(input);
  let changed = false;
  input.oninput = async (e) => {
    await setValue(e.target as HTMLInputElement);
    if (!changed) {
      const refresh = async () =>
        (input.value = await getVariableValue(source, {
          [generateRefreshKey(id, "selected", indexInList)]: refresh,
        }));
      await refresh();
    }
    changed = true;
  };
  label.appendChild(input);

  const span = document.createElement("span");
  label.appendChild(span);

  const style = document.createElement("style");
  style.innerHTML = `
  div[id="${element.id}"] > label {
    position: relative;
    width: 52px;
    height: 32px;
  }
  div[id="${element.id}"] > label > input {
    display: none;
  }
  div[id="${element.id}"] > label > span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #78788029;
    transition: 400ms;
    border-radius: 16px;
  }
  div[id="${element.id}"] > label > span:before {
    content: "";
    position: absolute;
    width: 28px;
    height: 28px;
    top: 2px;
    left: 2px;
    background: ${thumbColor};
    transition: 400ms;
    border-radius: 50%;
  }
  div[id="${element.id}"] > label > input:checked + span {
    background: ${trackColor};
  }
  div[id="${element.id}"] > label > input:focus + span {
    box-shadow: 0 0 1px ${trackColor};
  }
  div[id="${element.id}"] > label > input:checked + span:before {
    transform: translateX(20px);
  }`;
  element.appendChild(style);
};

export const createProgressIndicator = async (
  element: HTMLElement,
  component: ScreenComponent,
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType,
  getVariableValue: GetVariableValue
) => {
  const { id, maximumValue, primaryColor, secondaryColor, duration, loop } =
    component;
  const loopValue = loop?.source
    ? (await getVariableValue(loop.source)) === "true"
    : !!loop?.constant;
  const maximumValueValue = maximumValue?.source
    ? await getVariableValue(maximumValue.source)
    : maximumValue?.constant;
  const segments = maximumValueValue ? +maximumValueValue : 1;
  const trackColor = primaryColor
    ? await getColor(primaryColor, colorStyles, theme, getVariableValue)
    : colorStyles.primary[theme];
  const railColor = secondaryColor
    ? await getColor(secondaryColor, colorStyles, theme, getVariableValue)
    : colorStyles.neutral[theme];
  createProgress(
    id,
    element,
    trackColor,
    railColor,
    segments,
    duration,
    loopValue
  );
};

export const progressIndicatorAttribute = "indicator";
export const progressActiveAttribute = "active";
export const progressPassedAttribute = "passed";
const createProgress = (
  id: string,
  element: HTMLElement,
  trackColor: string,
  railColor: string,
  segments: number,
  duration?: number,
  infinite?: boolean,
  absolute?: boolean
) => {
  const progress = document.createElement("i");
  element.appendChild(progress);
  progress.style.display = "flex";
  if (absolute) {
    progress.style.position = "absolute";
    progress.style.top = "0";
    progress.style.left = "0";
    progress.style.width = "calc(100% - 16px)";
    progress.style.margin = "0 8px";
  }
  const progressWidth = progress.getBoundingClientRect().width;
  const progressGap = 4;
  const partWidth = (progressWidth - (segments - 1) * progressGap) / segments;
  const getPart = (
    x: number,
    width: number,
    color: string,
    attribute?: string
  ) =>
    `<rect x="${x}" width="${width}" height="4" rx="2" fill="${color}" ${attribute}/>`;
  const progressParts = new Array(segments)
    .fill(0)
    .map((el, i) => {
      const x = i * (partWidth + progressGap);
      return (
        getPart(x, partWidth, railColor) +
        getPart(x, 0, trackColor, progressIndicatorAttribute)
      );
    })
    .join("");
  progress.innerHTML = `
  <style>
    div[id='${element.id}'] > i > svg > rect[${progressActiveAttribute}] {
      width: ${partWidth}px;
      animation: animation-${id} ${duration || 5}s linear${
    segments === 1 && infinite ? " infinite" : ""
  };
    }
    @keyframes animation-${id} {
      0% {
        width: 0;
      }
      100% {
        width: ${partWidth}px;
      }
    }
    div[id='${element.id}'] > i > svg > rect[${progressPassedAttribute}] {
      width: ${partWidth}px;
    }
  </style>
  <svg width="100%" viewBox="0 0 ${progressWidth} 4" fill="none">
    ${progressParts}
  </svg>`;
};
