import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import {
  fetchAndActivate,
  getRemoteConfig,
  getValue,
} from "firebase/remote-config";

import firebaseWebConfig from "../firebase-web-config.json";
import { getParams, GetVariableValue } from "../hooks";
import {
  generateFirestoreId,
  readableError,
  VariableSourceType,
  VariableTransformTransform,
} from "../utils";
import { FirebaseContext, FP, FV, NFP } from "./index";

export interface FirebaseConfig {
  [key: string]: string;
}

interface Props {
  children: React.ReactNode;
  webFirebaseConfig?: FirebaseConfig;
  firestorePrefix?: string;
}

export const FirebaseProvider: React.FC<Props> = ({
  children,
  webFirebaseConfig,
  firestorePrefix = "",
}) => {
  const emailForSignInKey = "emailForSignIn";

  const app = firebase.initializeApp(webFirebaseConfig || firebaseWebConfig);

  const auth = firebase.auth();

  const firestore = firebase.firestore(app);

  const storage = firebase.storage(app);

  const googleProvider = new firebase.auth.GoogleAuthProvider();
  googleProvider.setCustomParameters({ prompt: "select_account" });

  const signInWithGoogle = async () => {
    await auth.signInWithPopup(googleProvider);
  };

  const createUserWithEmailAndPassword: FP = async (...props) => {
    const { email = "", password = "" } = await getParams(...props);
    await auth.createUserWithEmailAndPassword(email, password);
  };

  const signInWithEmailAndPassword: FP = async (...props) => {
    const { email = "", password = "" } = await getParams(...props);
    await auth.signInWithEmailAndPassword(email, password);
  };

  const sendPasswordResetEmail: FV = async (variable, getVariableValue) => {
    const email = variable.source
      ? await getVariableValue(variable.source)
      : "";
    await auth
      .sendPasswordResetEmail(email)
      .then(() => alert(`Link was sent to ${email}`));
  };

  const sendSignInLinkToEmail: FV = async (variable, getVariableValue) => {
    const email = variable.source
      ? await getVariableValue(variable.source)
      : "";
    await auth
      .sendSignInLinkToEmail(email, {
        url: window.location.origin,
        handleCodeInApp: true,
      })
      .then(() => localStorage.setItem(emailForSignInKey, email));
  };

  const reSendSignInLinkToEmail: FV = async (...props) => {
    const result = window.confirm("Trouble getting email? Resend?");
    if (result) {
      await sendSignInLinkToEmail(...props);
    }
  };

  const waitProfile = async (getVariableValue: GetVariableValue) => {
    const record = {
      collection: { name: "profiles" },
      selector: {
        source: {
          type: VariableSourceType.globalVariable,
          variableName: "currentUserId",
        },
      },
      transforms: [{ transform: VariableTransformTransform.exists }],
      type: VariableSourceType.collection,
    };
    const check = async () => (await getVariableValue(record)) === "true";
    let ready = await check();
    while (!ready) {
      await new Promise((r) => setTimeout(r, 1000));
      ready = await check();
    }
  };

  const getAssetRecord = (file: any) =>
    new Promise(async (resolve) => {
      const { name, type } = file;
      const [resourceType, format] = type.split("/");
      const storageRef = storage.ref(
        `uploads/${auth.currentUser?.uid}/${generateFirestoreId()}/${name}`
      );
      storageRef
        .put(file)
        .then(() =>
          storageRef.getDownloadURL().then((url) =>
            resolve({
              title: null,
              folder: "~",
              url,
              resourceType,
              format,
              width: null,
              height: null,
              bytes: null,
              duration: null,
              version: null,
              signature: null,
              error: null,
            })
          )
        )
        .catch((err) => alert(readableError(err.message)))
        .finally(() => resolve(null));
    });

  const httpClient = axios.create({
    headers: { "Content-Type": "application/json" },
  });

  httpClient.interceptors.request.use(
    async (config) => {
      const accessToken = await auth.currentUser?.getIdToken();
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (err) => Promise.reject(err)
  );

  httpClient.interceptors.response.use(
    (res) => res,
    async (err) => {
      if (err.response) {
        const originalConfig = err.config;
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          const accessToken = await auth.currentUser?.getIdToken(true);
          if (accessToken) {
            httpClient.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${accessToken}`;
          }
          return httpClient(originalConfig);
        }
      }
      return Promise.reject(err);
    }
  );

  const runFlow: NFP = async (name, ...props) => {
    const remoteConfig = getRemoteConfig(app);
    await fetchAndActivate(remoteConfig);
    const apiUrl = getValue(remoteConfig, "apiUrl").asString();
    if (!apiUrl) {
      throw new Error("Backend is not deployed.");
    } else {
      const data = await getParams(...props);
      await httpClient.post(`${apiUrl}/flows/${name}`, data);
    }
  };

  return (
    <FirebaseContext.Provider
      value={{
        f: {
          firestorePrefix,
          auth,
          firestore,
          signInWithGoogle,
          createUserWithEmailAndPassword,
          signInWithEmailAndPassword,
          sendPasswordResetEmail,
          sendSignInLinkToEmail,
          reSendSignInLinkToEmail,
          waitProfile,
          getAssetRecord,
          runFlow,
          emailForSignInKey,
        },
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
