import { SetURLSearchParams } from "react-router-dom";

import { F } from "../context";
import {
  generateRefreshKey,
  GetVariable,
  GetVariableValue,
  Update,
} from "../hooks";
import {
  BooleanVariable,
  BrandingColorStyles,
  BrandingFontStyle,
  BrandingThemeType,
  getTextValue,
  LocalizedContent,
  Resource,
  Screen,
  ScreenTopBar,
  setColors,
  setComponents,
} from "./index";

export const createTopBar = async (
  id: string,
  height: number,
  screen: HTMLElement,
  f: F,
  fontFamily: string,
  fontStyles: BrandingFontStyle[],
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType,
  resources: Resource[],
  desktopMode: boolean,
  setSearchParams: SetURLSearchParams,
  navigate: (pathname: string, search?: string) => void,
  navigateBack: () => Promise<void>,
  setPaywallOpen: React.Dispatch<React.SetStateAction<boolean>>,
  screens: Screen[],
  screenConfig: Screen,
  language: string,
  getPxValue: (val?: string) => string,
  getVariableValue: GetVariableValue,
  getVariable: GetVariable,
  update: Update,
  topBar?: ScreenTopBar,
  showBackButton?: BooleanVariable,
  screenTitle?: LocalizedContent
) => {
  const { backgroundColor } = screenConfig;

  const screenTopBar = document.createElement("div");
  screen.appendChild(screenTopBar);

  screenTopBar.id = id;
  screenTopBar.style.zIndex = "1";
  screenTopBar.style.position = "sticky";
  screenTopBar.style.marginBottom = `-${height}px`;
  screenTopBar.style.left = "0";
  screenTopBar.style.top = "0";
  screenTopBar.style.borderBottom = `1px solid ${colorStyles.surfaceVariant[theme]}`;
  screenTopBar.style.boxSizing = "border-box";
  screenTopBar.style.height = `${height}px`;
  screenTopBar.style.padding = "0 12px 11px";
  screenTopBar.style.display = "flex";
  screenTopBar.style.alignItems = "flex-end";

  await setColors(
    id,
    screenTopBar,
    colorStyles,
    theme,
    resources,
    getVariableValue,
    topBar?.backgroundColor || backgroundColor
  );

  const leading = document.createElement("div");
  leading.style.position = "relative";
  leading.style.width = "calc(100% / 3)";
  leading.style.display = "flex";
  leading.style.alignItems = "flex-end";
  screenTopBar.appendChild(leading);

  const headline = document.createElement("div");
  headline.style.position = "relative";
  headline.style.width = "calc(100% / 3)";
  headline.style.display = "flex";
  headline.style.alignItems = "flex-end";
  headline.style.justifyContent = "center";
  screenTopBar.appendChild(headline);

  const trailing = document.createElement("div");
  trailing.style.position = "relative";
  trailing.style.width = "calc(100% / 3)";
  trailing.style.display = "flex";
  trailing.style.alignItems = "flex-end";
  trailing.style.justifyContent = "flex-end";
  screenTopBar.appendChild(trailing);

  const showBackButtonValue = showBackButton?.source
    ? (await getVariableValue(showBackButton.source)) === "true"
    : !!showBackButton?.constant;
  if (showBackButtonValue) {
    const backButton = document.createElement("div");
    backButton.style.display = "flex";
    backButton.innerHTML = `
    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.0659 12.4121H5.50586" stroke="${colorStyles.onBackground[theme]}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.7859 19.4121L5.50586 12.4121L12.7859 5.41211" stroke="${colorStyles.onBackground[theme]}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `;
    leading.appendChild(backButton);

    backButton.className = "clickable";
    backButton.onclick = async () => await navigateBack();
  }

  if (topBar?.leadingComponents?.length) {
    await setComponents(
      f,
      topBar.leadingComponents,
      leading,
      fontFamily,
      fontStyles,
      colorStyles,
      theme,
      resources,
      false,
      false,
      desktopMode,
      setSearchParams,
      navigate,
      navigateBack,
      setPaywallOpen,
      screens,
      screenConfig,
      language,
      getPxValue,
      getVariableValue,
      getVariable,
      update,
      () => {},
      0,
      () => {}
    );
  }

  if (topBar?.headlineComponents?.length) {
    await setComponents(
      f,
      topBar.headlineComponents,
      headline,
      fontFamily,
      fontStyles,
      colorStyles,
      theme,
      resources,
      false,
      false,
      desktopMode,
      setSearchParams,
      navigate,
      navigateBack,
      setPaywallOpen,
      screens,
      screenConfig,
      language,
      getPxValue,
      getVariableValue,
      getVariable,
      update,
      () => {},
      0,
      () => {}
    );
  } else if (screenTitle) {
    const title = document.createElement("div");
    title.style.whiteSpace = "nowrap";
    title.style.fontFamily = fontFamily;
    title.style.fontWeight = "600";
    title.style.fontSize = "17px";
    title.style.lineHeight = "130%";
    title.style.color = colorStyles.onBackground[theme];
    const screenTitleRefresh = async () => {
      title.innerHTML = await getTextValue(
        language,
        getVariableValue,
        { [generateRefreshKey(id, "screenTitle")]: screenTitleRefresh },
        screenTitle
      );
    };
    await screenTitleRefresh();
    headline.appendChild(title);
  }

  if (topBar?.trailingComponents?.length) {
    await setComponents(
      f,
      topBar.trailingComponents,
      trailing,
      fontFamily,
      fontStyles,
      colorStyles,
      theme,
      resources,
      false,
      false,
      desktopMode,
      setSearchParams,
      navigate,
      navigateBack,
      setPaywallOpen,
      screens,
      screenConfig,
      language,
      getPxValue,
      getVariableValue,
      getVariable,
      update,
      () => {},
      0,
      () => {}
    );
  }
};
